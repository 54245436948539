import { IGetHintsAndSampleSolution } from 'types';
import { post } from '../utilities';
const API_PREFIX = 'evaluationPlatform';

const getHintsByQuestion = (payload: IGetHintsAndSampleSolution) => {
    return post(`${API_PREFIX}/getHints`, payload);
}

const getSampleSolutionsByQuestion = (payload: IGetHintsAndSampleSolution) => {
    return post(`${API_PREFIX}/getSampleSolutions`, payload);
}

export const HintsAndSampleSolutionService = {
    getHintsByQuestion,
    getSampleSolutionsByQuestion
}