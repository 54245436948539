import React from 'react';
import styled from 'styled-components';

const PaymentHistoryDiv = styled.div`
padding: 1rem;
    table {
    width: 100%;
    table-layout: fixed;
  }
  .ten {
    width: 10%;
}
th {
    border: solid;
  }
td {
    border: solid;
    word-break: break-all;
  }
`;

export const PaymentHistory = (props: any) => {
    return (
        <PaymentHistoryDiv>
            <table>
                <colgroup>
                    <col className="ten" />
                    <col className="ten" />
                    <col className="ten" />
                    <col className="ten" />
                    <col className="ten" />
                    <col className="ten" />
                    <col className="ten" />
                </colgroup>
                <thead>
                    <tr>
                        <th>Product ID</th>
                        <th>ID</th>
                        <th>Is Subscription Product</th>
                        <th>Paid</th>
                        <th>Stripe candidateId</th>
                        <th>Stripe transactionId</th>
                        <th>Subscription Id</th>
                        <th>Date</th>
                        <th>Track id</th>
                        <th>Expert id</th>
                        <th>Is Contract</th>
                    </tr>
                </thead>
                <tbody>
                    {props.paymentHistory.map((history: any, index: number) => (
                        <tr key={index}>
                            <td>{history.ProductId}</td>
                            <td>{history.id}</td>
                            <td>{history.isSubscriptionProduct ? "Yes" : "No"}</td>
                            <td>{`$ ${(history.paid / 100)}`}</td>
                            <td>{history.stripecandidateId}</td>
                            <td>{history.stripetransactionId}</td>
                            <td>{history.subscriptionId}</td>
                            <td>{history.timestamp}</td>
                            <td>{history.trackId}</td>
                            <td>{history.expertId}</td>
                            <td>{history.isContractProduct ? "Yes" : "No"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </PaymentHistoryDiv>
    );
};