import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

export const FailurePayment = () => {
    return (
        <>
            <div>
                <h2>Payment Failed. Try again after sometime</h2>
                <Link to="/">Go Home</Link>
            </div>
        </>
    );
};