import React from 'react';

interface IProps {
  fill?: string;
}

export default (props: IProps) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.3152 8.76641C30.9586 7.44103 29.9137 6.39624 28.5885 6.03936C26.1675 5.37695 16.4835 5.37695 16.4835 5.37695C16.4835 5.37695 6.79987 5.37695 4.37891 6.01425C3.07921 6.37075 2.00875 7.44121 1.65224 8.76641C1.01514 11.1872 1.01514 16.2075 1.01514 16.2075C1.01514 16.2075 1.01514 21.2532 1.65224 23.6487C2.00912 24.9738 3.05372 26.0186 4.3791 26.3755C6.82536 27.0381 16.4839 27.0381 16.4839 27.0381C16.4839 27.0381 26.1675 27.0381 28.5885 26.4008C29.9139 26.0441 30.9586 24.9993 31.3155 23.6741C31.9524 21.2532 31.9524 16.233 31.9524 16.233C31.9524 16.233 31.9779 11.1872 31.3152 8.76641ZM13.4003 20.8455V11.5695L21.4531 16.2075L13.4003 20.8455Z"
    />
  </svg>
);
