import React, { useEffect } from 'react';
import { CalendlyEventListener, closePopupWidget, InlineWidget, openPopupWidget } from 'react-calendly';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Modal from 'react-modal';
import { getModalDefaultSettings } from 'utilities';

export const CalendlyPopup = ({ open, onClose, onConfirm, customAnswers }: IProps) => {
    const candidateInfo = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    const modalStyle = getModalDefaultSettings('70%');

    const openCalendly = () => {
        openPopupWidget({
            url: 'https://calendly.com/kamal95/15min',
            prefill: {
                name: candidateInfo.fullname,
                email: candidateInfo.email,
                customAnswers: customAnswers
            },
            pageSettings: {
                hideLandingPageDetails: true
            }
        });
    }



    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={onClose}
                style={modalStyle}>
                <InlineWidget
                    url="https://calendly.com/kamal95/15min"
                    prefill={{
                        name: candidateInfo.fullname,
                        email: candidateInfo.email,
                        customAnswers: customAnswers
                    }}
                    pageSettings={{
                        hideLandingPageDetails: true
                    }}
                />
                <CalendlyEventListener onEventScheduled={(e: any) => onConfirm(e)} />
            </Modal>
        </div>
    );
}

interface IProps {
    open: boolean;
    onClose: any;
    onConfirm: (e: any) => void;
    customAnswers?: {
        a1: string;
    }
}