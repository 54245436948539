import { MainHeader } from 'containers/MainHeader';
import { Sidebar } from 'containers/Sidebar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { paymentService } from 'services/payment';
import { setTrackInfoForPayment } from 'store/payment';
import styled from 'styled-components';
import { post, useQuery } from 'utilities';

const StyledPaymentBody = styled.div`
 .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .content {
      padding: 60px 0;
  }
`

export const SuccessPayment = () => {
    const [loading, setLoading] = useState(true);
    let [isMaximizeContent, setMaximizeContent] = useState(false);
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();

    const handleMaximizeContent = () => {
        setMaximizeContent(!isMaximizeContent);
    };

    useEffect(() => {
        var session = {
            sessionId: query.get('session_id')
        };

        // should be moved to payment service
        if (session.sessionId) {
            paymentService.verifyCheckout(session)
                .then(res => {
                    (res?.message == "Success" && res.status == "200") ? setLoading(false) : history.push('/payment-fail');
                    const prodData = JSON.parse(res.response);
                    dispatch(setTrackInfoForPayment({
                        trackId: prodData.metadata.track,
                        trackPlan: prodData.metadata.isSubscriptionProduct == "true" ? "SUBSCRIPTION" : ""
                    }));
                })
                .catch(error => {
                    history.push('/payment-fail');
                });
        }
    }, []);

    return (
        <StyledPaymentBody>
            <Sidebar isMaximizeContent={isMaximizeContent} />
            <div className="header">
                <MainHeader
                    isMaximizeContent={isMaximizeContent}
                    handleMaximizeContent={handleMaximizeContent}
                    color="#315cd5"
                />
            </div>
            <div className="content">
                {loading && <h2>{'Please wait... Completing your transaction'}</h2>}
                {!loading && <div>
                    <h2>Congratulations! Your payment is successful.</h2>
                    <Link to="/">Go Home</Link>
                </div>}
            </div>
        </StyledPaymentBody>
    );
};