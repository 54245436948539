import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { BadgeCheck } from '@styled-icons/boxicons-regular/BadgeCheck'
import { CircleWithCross } from '@styled-icons/entypo/CircleWithCross';
import { Button } from 'components';

const modalStyle: Modal.Styles | undefined = {
    overlay: {
        background: 'rgba(0, 0, 0, 0.44)',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        position: 'fixed',
        zIndex: 999
    },
    content: {
        position: 'absolute',
        background: 'white',
        borderRadius: '10px',
        padding: '1em 2em',
        margin: 'auto',
        width: '40%',
        height: 'fit-content',
        overflow: 'none',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        zIndex: 999
    },
}

const Content = styled.div`
  text-align: center;
`;

const Footer = styled.div`
  text-align: center;
`;

export const MessageModal = ({ open, isSuccess, onClose }: IProps) => {

    return (
        <Modal isOpen={open}
            onRequestClose={onClose}
            style={modalStyle}>
            <Content>
                {isSuccess &&
                    <div>
                        <BadgeCheck width="100" fill="green" />
                        <h5>Payment Successful</h5>
                    </div>}
                {!isSuccess &&
                    <div>
                        <CircleWithCross width="100" fill="red" />
                        <h5>Payment Failed</h5>
                    </div>}
            </Content>
            <Footer>
                <Button type="button"
                    onClick={onClose}
                    className="bg--purple text--white text--bold mt-3">
                    {'OK'}
                </Button>
            </Footer>
        </Modal>
    );
}

interface IProps {
    open: boolean;
    isSuccess: boolean;
    onClose: any;
}