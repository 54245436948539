import { stringify } from 'querystring';
import { pickBy, identity } from 'lodash';
import store from '../store';
import { logout } from '../store/auth';
import { authService } from '../services';
import { BASE_API_URL, PPP_API_URL } from './constants';
import { logClientErrors } from 'store/evaluationPlatform';

export class ApiError {
  code: number;
  message: string;
  body: any;
  constructor(code: number, message: string, body: any) {
    this.code = code;
    this.message = message;
    this.body = body;
  }
}
Object.setPrototypeOf(ApiError, Error);

export async function handleResponse(response: any) {
  const statusCode = response.status;
  if (statusCode < 400) {
    return response.json();
  }

  return response
    .json()
    .catch(() => null)
    .then((body: any) => {
      if (statusCode === 401) {
        authService.removeAuthFromStorage();
        store.dispatch(logout());
        window.location.href = '/login';
      }
      logClientErrors({ errorMessage: body.apiMessage, remarks: 'API Error', stackTrace: '' })
      throw new ApiError(statusCode, body.apiMessage, response.statusText);
    });
}

export function request(path: string, opts = {}, isPPP = false) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const fullPath = isPPP ? `${PPP_API_URL}/${path}` : `${BASE_API_URL}/${path}`
  return fetch(`${fullPath}`, {
    mode: 'cors',
    headers,
    ...opts,
  }).then(handleResponse);
}

export function get(path: string, params = {}, opts = {}, isPPP = false) {
  const search = stringify(pickBy(params, identity));
  return request(`${path}?${search}`, {
    method: 'GET',
    ...opts,
  }, isPPP);
}

export function post(path: string, body = {}, opts = {}, isPPP = false) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(body),
    ...opts,
  }, isPPP);
}

export function put(path: string, body = {}, opts = {}) {
  return request(path, {
    method: 'PUT',
    body: JSON.stringify(body),
    ...opts,
  });
}

export function del(path: string, params = {}, opts = {}) {
  const search = stringify(params);
  return request(`${path}?${search}`, {
    method: 'DELETE',
    ...opts,
  });
}

function getUrlEncodedString(obj: any) {
  var formBody = [];
  for (var property in obj) {
    let encodedKey = encodeURIComponent(property);
    if (Array.isArray((obj)[property])) {
      let arrayFormBody: string[] = [];
      (obj as any)[property].forEach((id: string) => {
        let encodedV = encodeURIComponent(id);
        arrayFormBody.push(encodedKey + "=" + encodedV);
      })
      formBody.push(arrayFormBody.join("&"));
    }
    else {
      var encodedValue = encodeURIComponent((obj as any)[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
  }
  return formBody.join("&");
}