import { MessageModal } from 'components/Modals/MessagePopup';
import React, { useState } from 'react';

type MessagePopupContextType = {
    success: () => void;
    fail: () => void;
}

const MessagePopupContext = React.createContext<MessagePopupContextType>({
    success: () => { },
    fail: () => { }
});

type MessagePopupProp = {
    children: React.ReactNode;
}

export const MessagePopup = ({ children }: MessagePopupProp) => {
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const successHandler = () => { setOpen(true); setSuccess(true); }
    const failHandler = () => { setOpen(true); setSuccess(false); }
    const onClose = () => setOpen(false);

    //TOdo-- add custom message
    return (
        <MessagePopupContext.Provider value={{ success: successHandler, fail: failHandler }}>
            <MessageModal
                open={open}
                isSuccess={success}
                onClose={onClose}
            />
            {children}
        </MessagePopupContext.Provider>
    )
}

export const useMessagePopup = () => React.useContext(MessagePopupContext);