const isCandidateAuthenticated = () => {
    return !!localStorage.getItem('candidateId');
}

const getValueBrowserStorage = (key: string) => {
    return localStorage.getItem(key);
}

const setValueBrowserStorage = (key: string, value: string) => {
    return localStorage.setItem(key, value);
}

const clearBrowserStorage = (key: string) => {
    localStorage.removeItem(key);
}

export {
    isCandidateAuthenticated,
    clearBrowserStorage,
    getValueBrowserStorage,
    setValueBrowserStorage
}