import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { history } from '../utilities';
import auth from './auth';
import evaluationPlatform from './evaluationPlatform';
import payment from './payment';
import { isProd } from 'utilities/constants';

const middleware = [...getDefaultMiddleware(), routerMiddleware(history)];

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  evaluationPlatform,
  payment
});

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

!isProd() && (window.store = store)

export type RootState = ReturnType<typeof rootReducer>
export default store;
