export const DEFAULT_MARKET_NAME = 'INTERVIEW_HELP';

export const DEFAULT_TOKEN = '123';

export const DEFAULT_AUTO_SAVING_TIME = 20000;

export const DEFAULT_GET_ANS_STATUS_TIME = 300000;

interface IQuestionStatus {
  [key: string]: string;
}

export const answerType = {
  RICH_TEXT: 'RICH_TEXT',
  CODE: 'CODE',
  DRAWING: 'DRAWING',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO'
}

export const QUESTION_STATUS_TEXT: IQuestionStatus = {
  UNANSWERED: 'UNANSWERED',
  ANSWERED: 'ANSWERED',
  SUBMITTED_FOR_REVIEW: 'SEND_FOR_REVIEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  FEEDBACK_RECEIVED: 'FEEDBACK_RECEIVED',
  FEEDBACK_VIEWED: 'FEEDBACK_VIEWED_BY_CANDIDATE',
  LOOP_UNANSWERED: 'LOOP_UNANSWERED',
  LOOP_ANSWERED: 'LOOP_ANSWERED',
  LOOP_SUBMITTED_FOR_REVIEW: 'LOOP_SEND_FOR_REVIEW',
  LOOP_UNDER_REVIEW: 'LOOP_UNDER_REVIEW',
  LOOP_FEEDBACK_RECEIVED: 'LOOP_FEEDBACK_RECEIVED',
  LOOP_FEEDBACK_VIEWED: 'LOOP_FEEDBACK_VIEWED_BY_CANDIDATE',
};

const DEV = {
  url: {
    BASE_API_URL: 'https://testapi.interviewhelp.io/es',
    PPP_API_URL: 'http://ab3aee6c0110146cdabf7fe27e3da055-220798744.us-west-2.elb.amazonaws.com:91'
  },
};

const PROD = {
  url: {
    BASE_API_URL: 'https://api.interviewhelp.io/es',
    PPP_API_URL: 'https://ppp.interviewhelp.io'
  },
};
const STAGING = {
  url: {
    BASE_API_URL: 'https://stagingapi.interviewhelp.io/es',
  },
};

export const evaluationMetricConstants = {
  NOT_EVALUATED: 'NOT_EVALUATED',
  KNOWS: 'KNOWS',
  DONT_KNOW: 'DONT_KNOW',
  PARTIALLY_KNOWS: 'PARTIALLY_KNOWS'
}

export const evalMetricsValueMapper: any = {
  PARTIALLY_KNOWS: 'Partially',
  KNOWS: 'Yes',
  DONT_KNOW: 'No',
}

export const languages = ['javascript', 'java', 'python', 'golang', 'ruby', 'csharp', 'typescript', 'mysql', 'css'
]

export const isProd = () => {
  return process.env.REACT_APP_MODE === 'production' ? true : false
}
export const PPP_API_URL = isProd() ? PROD.url.PPP_API_URL : DEV.url.PPP_API_URL;

export const evalPlanUpgradeMessage = "Upgrade your plan";
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;


export const BASE_ASSETS_URL = "https://assets.interviewhelp.io";

export const Expert_Session_Id = "expertSessionId";
