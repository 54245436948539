import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';

const StyledFeedback = styled.div`
    margin-top: 10px;
    .ql-editor{
      height: auto;
    }
  .profile-icon {
    float: left;
    z-index: 100;
    margin-left: -50px !important;
    margin-top: 5px;
    width: 35px;
    background-color: #5B94E3;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: white;
    position: relative;
    text-align: center;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    padding: 4px;
  }
`;

interface IProps {
  feedback: any;
}

export const FeedbackField: React.FC<IProps> = (props) => {
  const feedback = props.feedback;
  const expert = useSelector(
    (state: any) => state.evaluationPlatform.currentTrack
    .candidateTrack[0].experts.find((expert:any) => expert._id === feedback.expertId))
  return (
    <StyledFeedback>
      <div className='profile-icon ml-1' data-for='expert-name' data-tip={expert?.fullname}>
        {expert?.fullname?.charAt(0).toUpperCase()}
      </div>
      <ReactTooltip id='expert-name' place="left" type="dark" />
      <RichTextEditor
        disabled={true}
        id={feedback?._id}
        value={feedback?.feedback}
      />
    </StyledFeedback>
  );
};
