import React, { useState } from 'react';
import { Sidebar, MainHeader } from 'containers';
import { ExpertVideoPage, YourVideoPage } from 'pages';
import styled from 'styled-components';

const StyledDashboardPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    padding-top: 20px;
    padding-left: ${(props) => props.theme.isMaximizeContent ? '0px' : '78px'};
    margin-top: 57px;
    display: flex;
    transition: 1s;
    flex-direction: column;
    width: ${(props) => props.theme.isMaximizeContent ? '100%' : 'calc(100% - 78px)'};
    height: calc(100vh - 80px);
  }
  .button-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: solid 2px #efefef;
  }
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
  }
`;

const PageSelectButton = styled.button<any>`
  margin-left: 50px;
  border-radius: 20px;
  width: fit-content;
  height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }
  transition: 0.3s;
  background: '#e9e9e9';
  color: ${(props) => (props.selected ? '#5B94E3' : 'black')};
  padding: 9px 15px;
  cursor: pointer;
  font-family: Khula;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  overflow: hidden;
`;

export const VideosPage: React.FC<any> = (props) => {
  const [selectedPage, selectPage] = useState('ExpertPage');
  let pageContent = <YourVideoPage />;
  let [isMaximizeContent, setMaximizeContent] = useState(false);

  if (selectedPage === 'ExpertPage') {
    pageContent = <ExpertVideoPage />;
  }

  const handleClickExpertPage = () => {
    selectPage('ExpertPage');
  };

  const handleClickYourPage = () => {
    selectPage('YourPage');
  };

  const handleMaximizeContent = () => {
    setMaximizeContent(!isMaximizeContent);
  };

  const ButtonsBar = () => (
    <div className="button-bar">
      <span className="page-title">Videos</span>
      <PageSelectButton selected={selectedPage === 'ExpertPage'} onClick={handleClickExpertPage}>
        Expert
      </PageSelectButton>
      <PageSelectButton selected={selectedPage === 'YourPage'} onClick={handleClickYourPage}>
        Yours
      </PageSelectButton>
    </div>
  );

  const MainContent = () => <div className="main-content">{pageContent}</div>;

  return (
    <StyledDashboardPage  theme={{isMaximizeContent}}>
      <Sidebar isMaximizeContent={isMaximizeContent}/>
      <div className="content">
        <div className="header">
          <MainHeader color="#315cd5" isMaximizeContent={isMaximizeContent} handleMaximizeContent={handleMaximizeContent} />
        </div>
        <div className="main">
          <ButtonsBar />
          <MainContent />
        </div>
      </div>
    </StyledDashboardPage>
  );
};
