import { useStripePayment } from 'components/Common/customHooks/stipePayment';
import { ChooseExpertModal } from 'components/Modals/ChooseExpertModal';
import { PaymentMethodsModal } from 'components/Modals/PaymentMethodModal';
import { useQuestions } from 'components/Question/useQuestions';
import { useLoader } from 'context/GlobalLoader';
import { useMessagePopup } from 'context/messagePopup';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { setValueBrowserStorage } from 'services/browserStorageService';
import { IPaymentMethod } from 'types/Payments';
import { notEmpty } from 'utilities';
import { Expert_Session_Id } from 'utilities/constants';

interface ISelectedExpertInfo {
    productId: string;
    expertId: string;
    price: string;
}

export const ExpertSelectionModal = ({ isShowChooseExpert, setShowChooseExpert, questionId, answerId, submitFeedbackToExpert }: any) => {
    const loader = useLoader();
    const { enqueueSnackbar } = useSnackbar();
    const { startCheckout } = useStripePayment();
    const [savedCards, setSavedCards] = useState<IPaymentMethod[]>([]);
    const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
    const { getPaymentMethods, proceedToCheckout, doDirectPayment, isActiveContractPlan, validateActiveContractPlan } = useQuestions();
    const [selectedExpertInfo, setSelectedExpertInfo] = useState<ISelectedExpertInfo>({ productId: '', expertId: '', price: '' });
    const message = useMessagePopup();

    const handleSubmitResponseToExpert = (productId: string, expertId: string, price: number, paid: boolean) => {
        if (!isActiveContractPlan()) {
            if (notEmpty(productId) && !paid) {
                loader.showLoader();
                setSelectedExpertInfo({
                    productId: productId,
                    expertId: expertId,
                    price: '$' + price.toFixed(2)
                });

                getPaymentMethods().then(methods => {
                    if (notEmpty(methods)) {
                        setShowChooseExpert(false);
                        setSavedCards(methods);
                        setOpenPaymentMethods(true);
                        loader.hideLoader();
                    }
                    else {
                        continueCheckout(productId, expertId);
                    }
                })
            } else if (notEmpty(productId) && paid) {
                submitFeedbackToExpert(expertId);
            }
            else {
                enqueueSnackbar("Requested expert entry does not exist", {
                    variant: 'error',
                    autoHideDuration: 2500,
                });
            }
        }
        else {
            validateActiveContractPlan().then((isActiveContract: boolean) => {
                if (isActiveContract) submitFeedbackToExpert(expertId);
                else {
                    enqueueSnackbar("Plan is not contract active", {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                }
            });
        }
    };

    const onPaymentMethodSelect = (paymentMethodId: string) => {
        if (paymentMethodId == 'new') {
            continueCheckout(selectedExpertInfo.productId, selectedExpertInfo.expertId);
        } else if (notEmpty(paymentMethodId) && notEmpty(selectedExpertInfo.productId) && notEmpty(selectedExpertInfo.expertId)) {
            loader.showLoader();
            doDirectPayment(paymentMethodId, selectedExpertInfo.productId, selectedExpertInfo.expertId, questionId, answerId)
                .then(res => {
                    if (res.status === "200" && res.paymentData[0]?.paid && res.paymentData[0]?.status == "succeeded") {
                        message.success();
                        submitFeedbackToExpert(selectedExpertInfo.expertId);
                    } else {
                        loader.hideLoader();
                        enqueueSnackbar('Payment failed, please try later', {
                            variant: 'error',
                            autoHideDuration: 2500,
                        });
                    }
                })
                .catch(err => {
                    loader.hideLoader();
                    enqueueSnackbar(err?.message, {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                });
        } else {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    }

    const continueCheckout = (productId: string, expertId: string) => {
        if (notEmpty(productId) && notEmpty(expertId)) {
            loader.showLoader();
            proceedToCheckout(productId, expertId, questionId, answerId)
                .then((res: any) => {
                    const sessionId = res.sessionId;
                    setValueBrowserStorage(Expert_Session_Id, sessionId);
                    loader.hideLoader();
                    startCheckout(sessionId);
                })
                .catch(err => {
                    loader.hideLoader();
                    enqueueSnackbar(err?.message, {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                });
        } else {
            enqueueSnackbar('No expert exist', {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    }

    const clearSelectedExpertInfo = () => {
        setSelectedExpertInfo({
            productId: '',
            expertId: '',
            price: ''
        });
    }

    return (
        <>
            <ChooseExpertModal
                Modal={Modal}
                questionId={questionId}
                hideChooseExpert={() => {
                    clearSelectedExpertInfo()
                    setShowChooseExpert(false)
                }}
                isShowChooseExpert={isShowChooseExpert}
                handleSubmitResponseToExpert={handleSubmitResponseToExpert}
            />
            <PaymentMethodsModal
                open={openPaymentMethods}
                cards={savedCards}
                amount={selectedExpertInfo.price}
                onClose={() => {
                    clearSelectedExpertInfo()
                    setOpenPaymentMethods(false)
                }}
                onSelect={onPaymentMethodSelect}
            />
        </>
    )
}