import React from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.div<any>`
  display: flex;
  flex-direction: column;
  filter: ${(props) => (props.disable ? 'opacity(25%)' : 'unset')};

  .iconText {
    font-family: Khula;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: ${(props: any) => props.fill};
  }
`;

interface Prop {
  icon?: any;
  fill?: string;
  iconText?: string;
  className?: string;
  disable?: boolean;
}

export const IconButton: React.FC<Prop> = (props) => {
  const Icon = props.icon;
  return (
    <StyledIconButton disable={props.disable} fill={props.fill}>
      <div className="iconBody">
        {/* <img className="iconImage" src={props.icon} alt="icon_button" /> */}
        <Icon fill={props.fill} />
      </div>
      <div className="iconText">{props.iconText}</div>
    </StyledIconButton>
  );
};
