import React from 'react';
import { CategoryField } from '../../components';
import styled from 'styled-components';

const StyledTreeSidebar = styled.div`
  margin-left: 78px;
  overflow-y: auto;
  height: calc(100vh - 57px);
  width: 285px;
  opacity: ${(props: any) => (props.isMaximizeContent ? 0 : 1)};
  transition: opacity 1s;
  background: #eeeeee;
  text-align: left;
  position: fixed;
  .trackname {
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
  }
`;

interface IProps {
  category: Array<any>;
  trackName: string;
  isMaximizeContent?: boolean;
  setCapability: any;
  setScrollPosition: Function;
}

export const TreeSidebar: React.FC<IProps> = (props) => {
  return (
    <StyledTreeSidebar {...props}>
      <div className="trackname">{props.trackName}</div>
      {props.category?.map((item, idx) => (
        <CategoryField 
          value={item} 
          key={idx} 
          idx={idx}
          setScrollPosition={props.setScrollPosition} 
          setCapability={props.setCapability} />
      ))}
    </StyledTreeSidebar>
  );
};
