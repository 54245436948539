import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { isCandidateAuthenticated } from '../../services/browserStorageService';

interface IPrivateRouteProps extends RouteProps {
    component: any;
}

export const PrivateRoute = ({ component: Component, location, ...rest }: IPrivateRouteProps) => {

    const isAuth = () => {
        return isCandidateAuthenticated();
    }

    return (
        <Route
            {...rest}
            render={(props: any) => (
                isAuth() ? (<Component {...props} />) : (<Redirect to='/' />)
            )} />
    )
}