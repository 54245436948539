import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { QuestionField, StatusButtonBar, TracksDescription } from '../../components';

const StyledQuestionContainer = styled.div`
  display: flex;
  z-index: 1;
  background: white;
  margin-left: ${(props: any) => (props.isMaximizeContent ? '0px' : '363px')};
  width: ${(props: any) => (props.isMaximizeContent ? '100%' : 'calc(100% - 285px)')};
  transition: 1s;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 100px;
  align-items: center;
  padding-top: 44px;
  .capability__title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .question__status__bar {
    margin-top: 28px;
    padding-left: 48px;
    width: -webkit-fill-available;
  }
  .question__main {
    width: -webkit-fill-available;
  }
`;

interface IProps {
  isMaximizeContent: boolean;
  candidateTrackId: string;
  capability: any;
  handleMaximizeContent: any;
  setCapability: any;
  selectedCapabilityId: string;
  setAnswer: any;
  saveResponseForQuestionOfCandidateTrack: any;
  Modal: any;
  setQuestionId: any;
  currentQuestionId: string;
  submitResponseToExpert: any;
  setFeedback: any;
  setQuestionFeedbackViewed: Function;
  candidateViewedExpertFeedback: Function;
  candidate: any;
  setCurrentAnsVersionId: Function,
  saveCandidateLastActivity: Function,
  goToPaymentPage: Function,
  triggerFeedback: any;
}

export const QuestionContainer: React.FC<IProps> = (props) => {
  let capabilityId = props.selectedCapabilityId || '';
  let currentCapability = props.capability?.filter((item: any) => item.capabilityId === capabilityId)[0];
  const description = currentCapability?.description;
  const capabilityName = currentCapability?.capabilityName;
  const capabilitiyStatus = currentCapability?.capabilityStatus;
  let questions = currentCapability?.questions;
  let capScrollPosition = currentCapability?.scrollPosition?.scrollTop || 0;
  const [questionFilter, setQuestionFilter] = useState('All');

  useEffect(() => {
    setQuestionFilter('All');
    questions = currentCapability?.questions;
    // eslint-disable-next-line
  }, [props.selectedCapabilityId, currentCapability]);

  useEffect(() => document.documentElement.scrollTo(0, capScrollPosition), [props.selectedCapabilityId])

  const getQuestionIdx = (question: any) => {
    return currentCapability?.questions.map((question: any) => question.question._id).indexOf(question.question._id);
  }

  const CapabilityTitle = () => <div className="capability__title">{capabilityName}</div>;
  return (
    <StyledQuestionContainer {...props}>
      <CapabilityTitle />
      <TracksDescription description={description} />
      <div className="question__status__bar">
        <StatusButtonBar
          capabilitiyStatus={capabilitiyStatus}
          setQuestionFilter={setQuestionFilter}
          questionFilter={questionFilter}
        />
      </div>
      <div className="question__main">
        {questions &&
          questions
            .filter((item: any) => {
              if (questionFilter !== 'All') return item.question?.status === questionFilter;
              return true;
            })
            .map((question: any, idx: number) => {
              return (
                <QuestionField
                  idx={getQuestionIdx(question)}
                  key={idx}
                  candidateTrackId={props.candidateTrackId}
                  capabilityId={capabilityId}
                  questionSet={question}
                  handleMaximizeContent={props.handleMaximizeContent}
                  isMaximizeContent={props.isMaximizeContent}
                  setAnswer={props.setAnswer}
                  saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
                  Modal={props.Modal}
                  setQuestionId={props.setQuestionId}
                  currentQuestionId={props.currentQuestionId}
                  submitResponseToExpert={props.submitResponseToExpert}
                  setFeedback={props.setFeedback}
                  setQuestionFeedbackViewed={props.setQuestionFeedbackViewed}
                  candidateViewedExpertFeedback={props.candidateViewedExpertFeedback}
                  candidate={props.candidate}
                  setCurrentAnsVersionId={props.setCurrentAnsVersionId}
                  saveCandidateLastActivity={props.saveCandidateLastActivity}
                  goToPaymentPage={props.goToPaymentPage}
                  triggerFeedback={props.triggerFeedback}
                />
              );
            })}
      </div>
    </StyledQuestionContainer>
  );
};
