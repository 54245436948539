import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { ISaveResponseForQuestionOfCandidateTracks as IPayload } from 'types';
import { DEFAULT_TOKEN, DEFAULT_AUTO_SAVING_TIME, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { getQuillContent, usePrevious } from 'utilities/helperFunctions';
import { CodeEditor } from 'components/Common/Editors/CodeEditor';
import { answerType, languages } from 'utilities/constants';
import { saveCandidateLastActivity } from 'store/evaluationPlatform';
import { Form } from 'react-bootstrap';
import useStateRefInSync from 'components/Common/customHooks/useStateRefInSync';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';

import 'react-tabs/style/react-tabs.css';

const AnswerContainer = styled.div`
  width: 100%;
  height: auto;
  transition: 1s;
  position: relative;
  .react-tabs{
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }
  .react-tabs__tab-list{
    margin: 0;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    text-align: left;
    box-shadow: 0px -2px 8px 0 rgba(0,0,0,0.25);
  }
  .react-tabs__tab-panel{
    textarea{
      box-shadow: 0px 0px 10px rgba(0,0,0,0.25) !important;
    }
  }
  .react-tabs__tab{
    top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    bottom: 0;
    div{
      display: flex;
    }
    select{
      height: 0px;
      width: 0px;
      border: 0px;
      background: center;
      padding-top: 15px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .react-tabs__tab--selected{
    color: white;
    background-color: #5b94e3;
  }
  .function__icons {
    right: 16px;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    justify-content: space-between;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .function__icons img {
    cursor: pointer;
    transform: ${(props: any) => (props.isMaximizeContent ? 'rotate(180deg)' : 'none')};
    transition: 1s ease all;
  }
`;

interface IProps {
  handleMaximizeContent: any;
  isMaximizeContent: boolean;
  candidateTrackId: string;
  capabilityId: string;
  questionId: string;
  textId: string;
  answer: any;
  setAnswer: any;
  saveResponseForQuestionOfCandidateTrack: any;
  isSaved: boolean;
  setIsSaved: any;
  questionStatusText: string;
  setQuestionStatusText: any;
  question: any;
  answerStatus: string;
  answerVersion: number;
  setQuestionId: Function;
}

export const AnswerField: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();

  const [isDisabled, setDisable] = useState(false);
  const [hasSaveError, setHasSaveError] = useState(false);
  const [richText, updateRichText, richTextRef] = useStateRefInSync(props.answer?.answer?.answer || '')
  const [codeAns, updateCodeAns, codeAnsRef] = useStateRefInSync(props?.answer?.answer?.codeAnswer || '')
  const [codeType, updateCodeType, codeTypeRef] = useStateRefInSync(props?.answer?.answer?.codeType || languages[0])
  const [tabIndex, setTabIndex] = useState(codeAns ? 0 : 1);

  const saving = useSelector((state: any) => state.evaluationPlatform.saving);
  const selectedCapabilityId = useSelector((state: any) => state.evaluationPlatform.selectedCapabilityId);
  const prevSelectedCapabilityId = usePrevious(selectedCapabilityId);

  let hasNeedToSave = useRef(false);

  useEffect(() => {
    if (props.answer && (props.question.status !== QUESTION_STATUS_TEXT.UNANSWERED || props.answerVersion > 0)) {
      if ((props.question.status !== QUESTION_STATUS_TEXT.UNANSWERED && props.question.status !== QUESTION_STATUS_TEXT.ANSWERED)
        || props.answerVersion > 0) {
        setDisable(true);
      }
      else if (props.question.status === QUESTION_STATUS_TEXT.UNANSWERED || props.question.status === QUESTION_STATUS_TEXT.ANSWERED) {
        setDisable(false)
      }
      updateRichText(props.answer?.answer?.answer);
    } else {
      props.setQuestionStatusText('');
      updateRichText('')
      setDisable(false);
      props.setIsSaved(false);
    }
    updateCodeAns(props.answer?.answer?.codeAnswer || '')
    updateCodeType(props.answer?.answer?.codeType || languages[0])
    // eslint-disable-next-line
  }, [props.answer, props.answerStatus, props.answerVersion, selectedCapabilityId]);

  useEffect(() => {
    setTabIndex(props?.answer?.answer?.codeAnswer || (!props?.answer?.answer?.codeAnswer && !props?.answer?.answer?.answer) ? 0 : 1)
  }, [props.answerVersion, selectedCapabilityId])

  useEffect(() => {
    checkStatusText();
    // eslint-disable-next-line
  }, [props.isSaved, hasSaveError]);

  useEffect(() => {
    if (!!prevSelectedCapabilityId && !!selectedCapabilityId && prevSelectedCapabilityId !== selectedCapabilityId) {
      props.setQuestionStatusText('');
      props.setIsSaved(false);
    }
    // eslint-disable-next-line
  }, [selectedCapabilityId]);

  useEffect(() => {
    if (props.answer?.answer?.codeAnswer && (props.answer?.answer?.codeType !== codeType)) {
      handleTextChange();
    }
  }, [codeType])


  const checkStatusText = () => {
    if (hasSaveError) {
      props.setQuestionStatusText('An error occured while saving your response.');
      return;
    }
  };

  const checkHasNeedToSave = () => {
    if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED || props.answerStatus === QUESTION_STATUS_TEXT.UNANSWERED) {
      if (props?.question?.answerType === answerType.CODE) {
        if (props.answer?.answer?.codeAnswer && (props.answer?.answer?.codeType !== codeType)) {
          hasNeedToSave.current = true;
          return;
        }
        if (codeAnsRef.current.trim() === '') {
          updateCodeAns('')
          if (props.answer && props.answer?.answer?.codeAnswer !== '') {
            hasNeedToSave.current = true;
            return
          }
        }
        else if (codeAnsRef.current !== props.answer?.answer?.codeAnswer) {
          hasNeedToSave.current = true;
          return;
        }
      }

      if (getQuillContent(richTextRef.current) === '') {
        updateRichText('')
        if (props.answer && props.answer?.answer?.answer !== '')
          hasNeedToSave.current = true
        else
          hasNeedToSave.current = false;
        return;
      }

      if (props.answer === null && getQuillContent(richTextRef.current) !== '') {
        hasNeedToSave.current = true;
        return;
      }
      if (props.answer && props.answer?.answer?.answer !== richTextRef.current) {
        hasNeedToSave.current = true
      }
    }
    else {
      hasNeedToSave.current = false;
    }
  };

  const handleSaveQuestion = () => {
    const capabilityIds: string[] =
      props.answer?.answer && props.answer.answer?.capabilityIds ? [...props.answer.answer?.capabilityIds] : [];
    if (capabilityIds.indexOf(props.capabilityId) < 0) capabilityIds.push(props.capabilityId);
    if (saving) {
      return;
    }
    props.setQuestionId(props.questionId)
    dispatch(saveCandidateLastActivity({ incCapId: true, incQueId: true }))
    const payload: IPayload = {
      token: DEFAULT_TOKEN,
      candidateTrackId: props.candidateTrackId,
      capabilityIds,
      questionId: props.questionId,
      answer: richTextRef.current,
      codeAnswer: codeAnsRef.current,
      codeType: codeTypeRef.current || undefined
    };
    let isUpdate = false;

    if (props.answer) {
      isUpdate = true;
      payload.questionAnswerId = props.answer.answer._id;
    }
    props.setQuestionStatusText('Saving...');

    props
      .saveResponseForQuestionOfCandidateTrack(payload)
      .then((res: any) => {
        if (res.payload) {
          hasNeedToSave.current = false;
          props.setIsSaved(true);
          const answer = res.payload.output;
          const data = {
            _id: answer.questionAnswerId,
            questionId: answer.questionId,
            answer: answer.answer,
            capabilityIds,
            codeAnswer: answer.codeAnswer,
            codeType: answer.codeType,
            candidateTrackId: answer.candidateTrackId,
            updateAt: new Date(answer.updateAt).toUTCString(),
          };
          props.setAnswer({
            capabilities: props.question.capabilities,
            questionId: props.questionId,
            answer: data,
            isUpdate,
          });
        } else {
          enqueueSnackbar(res.error?.message, {
            variant: 'error',
            autoHideDuration: 2500,
          });
          setHasSaveError(true);
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        });
        hasNeedToSave.current = false
        setHasSaveError(true);
      });
  };

  const handleTextChange = () => {
    setHasSaveError(false);
    checkHasNeedToSave();
    if (hasNeedToSave.current) {
      setHasSaveError(false);
      props.setIsSaved(false);
      handleSaveQuestion();
    }
  };

  const debounced = useCallback(_.debounce(handleTextChange, DEFAULT_AUTO_SAVING_TIME), [props.answer]);

  const handleBlur = () => {
    debounced.cancel();
    if (isDisabled)
      return;
    checkHasNeedToSave();
    if (hasNeedToSave.current) {
      setHasSaveError(false);
      props.setIsSaved(false);
      handleSaveQuestion();
    }
  };

  const handleSelect = ({ target: { value } }: any) => updateCodeType(value)

  const codeOnChange = (newCode: string) => {
    if (newCode !== codeAns) {
      debounced()
      updateCodeAns(newCode)
    }
  }

  const RTOnChange = (value: string, oldValue:string, source:string) => {
    if (source === 'user') {
      debounced()
      updateRichText(value)
    }
  }

  const getEditors = (type: string) => {
    const textEditor = <RichTextEditor
      disabled={isDisabled}
      placeholder="Type your response here..."
      value={richText}
      onBlur={handleBlur}
      onChange={RTOnChange}
      id={props.textId}
      data-testid="answerfield-textarea"
    />
    const codeEditor = <CodeEditor
      disabled={isDisabled}
      codeType={codeType}
      onBlur={handleBlur}
      onChange={codeOnChange}
      code={codeAns}
      id={`code-${props.textId}`}
    />
    switch (type) {
      case answerType.RICH_TEXT:
        return textEditor;
      case answerType.CODE:
        return <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab style={{ borderTopLeftRadius: 'inherit' }}>
              <div>
                {`Code ${codeAns !== '' ? ` °` : ''}`}
                <Form.Control
                  onChange={handleSelect}
                  value={codeType}
                  as="select">
                  {languages.map(lang => <option disabled={isDisabled && codeType !== lang ? true : false} key={lang}>{lang}</option>)}
                </Form.Control>
              </div>
            </Tab>
            <Tab>{`Text ${getQuillContent(richText) !== '' ? ` °` : ''}`}</Tab>
          </TabList>

          <TabPanel>
            {codeEditor}
          </TabPanel>
          <TabPanel>
            {textEditor}
          </TabPanel>
        </Tabs>
      default:
        return textEditor
    }
  }

  return (
    <AnswerContainer {...props} data-testid="answerfield-container">
      {getEditors(props?.question?.answerType)}
    </AnswerContainer>
  );
};
