import React from 'react';
import { SignupHeader, Signup } from '../../containers';

export const SignupPage = (props: any) => {
  return (
    <>
      <SignupHeader background="#315CD5" color="white" />
      <Signup history={props.history} />
    </>
  );
};
