import React, { useState, useEffect } from 'react';
import {
  StyledHints,
  StyledSampleModal,
  StyledPrevIcon,
  PreviousIcon,
  StyledSolutions,
  StyledHintsHeader,
  StyledHintsBody,
  StyledNextIcon,
  NextIcon,
} from './SampleSolutions.styles';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { StyledHintPagination } from '../Hints/Hints.styles';
import styled from 'styled-components';
import { notEmpty } from 'utilities';
// import RichTextEditor from 'components/Common/Editors/RichTextEditor';
// import { handleMarkedText } from 'utilities/helperFunctions';
import ReactMarkDown from 'react-markdown';

const FlexDiv = styled.div`
  display: flex;
  position: relative;
`;
const HintsDiv = styled.div`
.tabs{
  max-height: 130px;
  overflow: auto;
  }
`;

interface IProps {
  sampleSolutions: any;
}
const SampleSolutions = ({ sampleSolutions }: IProps) => {
  const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0);
  const [hidePrevIcon, setHidePrevIcon] = useState(false);
  const [hideNextIcon, setHideNextIcon] = useState(false);

  const HintPanel = ({ hints }: { hints: any }) => {
    return (
      <StyledHints>
        <Tabs>
          <TabList>
            {hints?.map((hint: string, hintIdx: number) => {
              return <Tab key={hintIdx}>Hint #{hintIdx + 1}</Tab>;
            })}
          </TabList>
          <HintsDiv>
            {hints?.map((hint: string, hintIdx: number) => {
              return (
                <TabPanel key={hintIdx}>
                  <div className={'tabs'} key={`tabpanel-${hintIdx}`}>
                    <ReactMarkDown linkTarget="_blank" source={hint} />
                    {/* <RichTextEditor
                      doNotAllowCopy={true}
                      disabled={true}
                      id={`hintRTE-${currentSolutionIndex}`}
                      value={handleMarkedText(hint)}
                      customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
                    /> */}
                  </div>
                </TabPanel>
              );
            })}
          </HintsDiv>
        </Tabs>
      </StyledHints>
    );
  };

  useEffect(() => {
    if (currentSolutionIndex === 0) setHidePrevIcon(true);
    else setHidePrevIcon(false);
    if (currentSolutionIndex === sampleSolutions.length - 1) setHideNextIcon(true);
    else setHideNextIcon(false);
  }, [currentSolutionIndex]);

  const handlePreviousHint = () => {
    setCurrentSolutionIndex(currentSolutionIndex - 1);
  };
  const handleNextHint = () => {
    setCurrentSolutionIndex(currentSolutionIndex + 1);
  };
  return (
    <>
      { notEmpty(sampleSolutions) &&
        (<StyledSampleModal>
          <StyledSolutions>
            <FlexDiv>
              <StyledHintsHeader>Sample Solution #{currentSolutionIndex + 1}</StyledHintsHeader>
              <StyledHintPagination>
                <StyledPrevIcon title="Previous Sample Solution" onClick={handlePreviousHint} theme={{ hidePrevIcon }}>
                  <PreviousIcon />
                </StyledPrevIcon>
                <StyledNextIcon title="Next Sample Solution" onClick={handleNextHint} theme={{ hideNextIcon }}>
                  <NextIcon />
                </StyledNextIcon>
              </StyledHintPagination>
            </FlexDiv>
            <StyledHintsBody>
              <ReactMarkDown linkTarget="_blank" source={sampleSolutions[currentSolutionIndex].description} />
              {/* <RichTextEditor
                doNotAllowCopy={true}
                disabled={true}
                id={'sampleSolutionRTE'}
                value={handleMarkedText(sampleSolutions[currentSolutionIndex].description)}
                customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
              /> */}
            </StyledHintsBody>
            <HintPanel hints={sampleSolutions[currentSolutionIndex].hints} />
          </StyledSolutions>
        </StyledSampleModal>)}
      { !notEmpty(sampleSolutions) && <h4>No sample solutions available</h4>}
    </>
  );
};

export default SampleSolutions;
