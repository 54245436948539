import { ICheckoutSavedCard, ICreateCheckoutRequestData, IExpertProductInfo, IGetContractPlanStatus, IGetProductsForTrack, IGetQuestionFeedbackPurchase } from 'types/Payments';
import { post, get } from '../utilities';

const getStripePublishKey = () => {
    return get(`config`, {}, {}, true);
};

const checkoutPaymentSession = (payload: ICreateCheckoutRequestData) => {
    return post('create-checkout-session', payload, {}, true);
};

const getExpertProducts = (payload: IExpertProductInfo) => {
    return post(`stripe/getExpertProductInfo`, payload, {}, true);
}

const getQuestionFeedbackPurchase = (payload: IGetQuestionFeedbackPurchase) => {
    return post(`stripe/getQuestionFeedBackPurchase`, payload, {}, true);
}

const getProductsForTrack = (payload: IGetProductsForTrack) => {
    return post(`stripe/getProductForCandidate`, payload, {}, true);
}

const verifyCheckout = (payload: any) => {
    return post(`checkout-session`, payload, {}, true);
}

const getPaymentMethods = (payload: any) => {
    return post(`paymentmethods`, payload, {}, true);
}

const checkoutWithSavedCard = (payload: ICheckoutSavedCard) => {
    return post(`paymentintent`, payload, {}, true);
}

const getContractPlanStatus = (payload: IGetContractPlanStatus) => {
    return post(`stripe/getStatusForContractProduct`, payload, {}, true);
}

export const paymentService = {
    getStripePublishKey,
    checkoutPaymentSession,
    getExpertProducts,
    getQuestionFeedbackPurchase,
    getProductsForTrack,
    verifyCheckout,
    getPaymentMethods,
    checkoutWithSavedCard,
    getContractPlanStatus
};
