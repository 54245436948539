import { useSelector } from 'react-redux';
import { evaluationPlatformService } from 'services';
import { paymentService } from 'services/payment';
import { RootState } from 'store';
import { IGetTracksForCandidateRequest } from 'types';
import { ICheckoutSavedCard, ICreateCheckoutRequestData, IGetProductsForTrack, IProductsForCandidate } from 'types/Payments';
import { getCurrentDomainUrl, notEmpty } from 'utilities';
import { DEFAULT_MARKET_NAME, DEFAULT_TOKEN } from 'utilities/constants';

export const usePlanProducts = () => {
    const candidateInfo = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    // const { trackId } = useSelector((state: RootState) => state.payment);

    const getProductForCandidate = (trackId: string): Promise<IProductsForCandidate> => {
        if (notEmpty(candidateInfo) && notEmpty(candidateInfo.email) && notEmpty(candidateInfo._id)) {
            const payload: IGetProductsForTrack = {
                candidateID: candidateInfo._id,
                email: candidateInfo.email,
                market: DEFAULT_MARKET_NAME,
                track: trackId
            }
            return paymentService.getProductsForTrack(payload);
        }
        else return Promise.reject({ message: 'Paramters not valid' });
    }

    const proceedToCheckout = (productId: string, trackId: string): Promise<any> => {
        const payload: ICreateCheckoutRequestData = {
            quantity: 1,
            productId: productId,
            candidateId: candidateInfo._id,
            market: DEFAULT_MARKET_NAME,
            track: trackId,
            successUrl: `${getCurrentDomainUrl()}/payment-success`,
            failureUrl: `${getCurrentDomainUrl()}/payment-fail`,
            questionId: null,
            expertId: null,
            answerId: null
        };
        return paymentService.checkoutPaymentSession(payload);
    }

    const getPaymentMethods = (): Promise<any> => {
        return paymentService.getPaymentMethods({ candidateId: candidateInfo._id })
            .then(res => res.paymentData)
            .catch(err => err);
    }

    const doDirectPayment = (paymentMethodId: string | null, productId: string, trackId: string) => {
        const payload: ICheckoutSavedCard = {
            quantity: 1,
            productId: productId,
            candidateId: candidateInfo._id,
            market: DEFAULT_MARKET_NAME,
            track: trackId,
            expertId: null,
            questionId: null,
            answerId: null,
            paymentMethodId: paymentMethodId
        };
        return paymentService.checkoutWithSavedCard(payload);
    }

    const getAllTakenTracks = (): Promise<any[]> => {
        const payload: IGetTracksForCandidateRequest = {
            token: DEFAULT_TOKEN,
            candidateId: candidateInfo._id
        }
        return evaluationPlatformService.getTracksForCandidate(payload)
            .then(res => {
                return res.output?.trackTaken;
            });
    }

    return { getProductForCandidate, proceedToCheckout, getPaymentMethods, doDirectPayment, getAllTakenTracks };
}
