import { MainHeader, Sidebar } from 'containers';
import { OtherSettingsPage, PaymentPage, TrackPage } from 'pages';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { notEmpty, useQuery } from 'utilities';
import { SettingsDescriptionPage } from '../SettingsDescriptionPage';

const StyledDashboardPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    padding-top: 20px;
    padding-left: ${(props) => (props.theme.isMaximizeContent ? '0px' : '78px')};
    margin-top: 57px;
    transition: 1s;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
  }
  .button-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: solid 2px #efefef;
  }
  .page-title {
    margin-left: 20px;
    color: #5b94e3;
    font-size: 20px;
    font-weight: bold;
  }
  .main-content {
    height: 100%;
  }
`;

const PageSelectButton = styled.button<any>`
  margin-left: 50px;
  border-radius: 20px;
  width: fit-content;
  height: 36px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }
  transition: 0.3s;
  background: '#e9e9e9';
  color: ${(props) => (props.selected ? '#5B94E3' : 'black')};
  padding: 9px 15px;
  cursor: pointer;
  font-family: Khula;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  overflow: hidden;
  pointer-events: ${(props) => (props.disable ? 'none' : 'initial')};
  filter: ${(props) => (props.disable ? 'opacity(25%)' : 'unset')};
`;

export const SettingsPage: React.FC<any> = (props) => {
  const [selectedPage, selectPage] = useState('');
  let [isMaximizeContent, setMaximizeContent] = useState(false);
  const [pageContent, setPageContent] = useState<JSX.Element>();
  const location = useQuery();
  const { trackId } = useSelector((state: RootState) => state.payment);

  useEffect(() => {
    const tabName = location.get('tab');
    if (selectedPage != tabName) loadTabContent(tabName);
  }, [location]);

  useEffect(() => {
    switch (selectedPage) {
      case 'TrackPage':
        setPageContent(<TrackPage history={props.history} />);
        break;
      case 'PaymentPage':
        showPaymentTab() ? setPageContent(<PaymentPage />) : handleClickButton('TrackPage');
        break;
      case 'OtherSettingsPage':
        setPageContent(<OtherSettingsPage />);
        break;
      default:
        setPageContent(<SettingsDescriptionPage />);
    }
  }, [selectedPage]);

  const loadTabContent = (tabName: string | null) => {
    if (notEmpty(tabName)) selectPage(tabName as string);
    else selectPage('');
  }

  const handleClickButton = (pageName: string) => {
    loadTabContent(pageName);
    props.history.push({
      pathname: '/settings',
      search: `?tab=${pageName}`
    });
  };

  const handleMaximizeContent = () => {
    setMaximizeContent(!isMaximizeContent);
  };

  const showPaymentTab = (): boolean => {
    return notEmpty(trackId);
  }

  const ButtonsBar = () => (
    <div className="button-bar">
      <span className="page-title">Settings</span>
      <PageSelectButton
        selected={selectedPage === 'TrackPage'}
        onClick={() => handleClickButton('TrackPage')}>
        Manage Tracks
      </PageSelectButton>
      <PageSelectButton
        selected={selectedPage === 'PaymentPage'}
        onClick={() => handleClickButton('PaymentPage')}
        disable={!showPaymentTab()}
      >
        Plan and Payments
      </PageSelectButton>
      <PageSelectButton
        selected={selectedPage === 'OtherSettingsPage'}
        onClick={() => handleClickButton('OtherSettingsPage')}
        disable={!showPaymentTab()}
      >
        Others
      </PageSelectButton>
    </div>
  );

  return (
    <>
      <StyledDashboardPage theme={{ isMaximizeContent }}>
        <Sidebar isMaximizeContent={isMaximizeContent} />
        <div className="content">
          <div className="header">
            <MainHeader
              color="#315cd5"
              isMaximizeContent={isMaximizeContent}
              handleMaximizeContent={handleMaximizeContent}
              disable={!!props?.location?.state?.disable}
            />
          </div>
          <div className="main">
            <ButtonsBar />
            <div className="main-content">{pageContent}</div>
          </div>
        </div>
      </StyledDashboardPage>
    </>
  );
};
