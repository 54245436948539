import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TracksPanel } from '../../containers';
import {
  getTracksForCandidate,
  createCandidateTrackForCandidate,
  getDetailsForCandidatebyCandidateTrackId,
} from 'store/evaluationPlatform';
import { DEFAULT_TOKEN } from 'utilities/constants';

const TrackPage = (props: any) => {
  useEffect(() => {
    const savedCandidateId = localStorage.getItem('candidateId');
    if (savedCandidateId !== null && savedCandidateId !== 'undefined') {
      props.getTracksForCandidate({
        token: DEFAULT_TOKEN,
        candidateId: savedCandidateId,
      });
    } else {
      props.getTracksForCandidate({
        token: DEFAULT_TOKEN,
        candidateId: props.candidate?._id,
      });
    }
  }, []);

  return (
    <TracksPanel
      createCandidateTrackForCandidate={props.createCandidateTrackForCandidate}
      getDetailsForCandidatebyCandidateTrackId={props.getDetailsForCandidatebyCandidateTrackId}
      candidate={props.candidate}
      tracksData={props.tracksData}
      className="dashboard"
      history={props.history}
      disable={!!props?.history?.location?.state?.disable}
    />
  );
};

const mapStateToProps = (state: any) => ({
  candidate: state.evaluationPlatform.candidate,
  tracksData: state.evaluationPlatform.tracksData,
  loading: state.evaluationPlatform.loading,
});

const mapDispatchToProps = {
  getTracksForCandidate,
  createCandidateTrackForCandidate,
  getDetailsForCandidatebyCandidateTrackId,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackPage);
