import { post } from '../utilities';

const login = (email: string, password: string) => {
  const user = { email, password };
  return post('login', { user });
};

const signup = (user: any) => {
  return post('signup', user);
};

const setAuthInStorage = (userAuth: any) => {
  localStorage.setItem('auth', JSON.stringify(userAuth));
};

const getAuthFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('auth') || '');
  } catch (err) {
    return null;
  }
};

const removeAuthFromStorage = () => {
  localStorage.removeItem('auth');
};

export const authService = {
  login,
  signup,
  setAuthInStorage,
  getAuthFromStorage,
  removeAuthFromStorage,
};
