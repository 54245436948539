import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, PasswordField, MarketSelectDropdown } from 'components';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { createNewCandidateForMarketAction } from 'store/evaluationPlatform';
import styled from 'styled-components';
import { isProd } from 'utilities/constants';

const SignupContainer = styled.div`
  form > div,
  form > button {
    width: 100%;
    margin-bottom: 18px;
  }
  form {
    width: 100%;
  }
  h1 {
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: bold;
  }
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: 538px;
  margin: auto;
`;

interface IValues {
  fullname: string;
  email: string;
  password: string;
  confirmPassword: string;
  market: string;
}

const _Signup = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [marketList, setMarketList] = useState<Array<any>>([]);
  useEffect(() => {
    setMarketList(props.marketList);
  }, [props.marketList]);
  //
  const handleSignup = (values: IValues, { setSubmitting, setErrors, setStatus, resetForm }: any) => {
    const payload = {
      token: '123',
      market: values.market,
      fullname: values.fullname,
      email: values.email,
      password: values.password,
    };
    props
      .createNewCandidateForMarketAction(payload)
      .then((res: any) => {
        setStatus({ success: true });
        if (res.payload?.apiStatus === 'SUCCESS') {
          enqueueSnackbar('Successfully created!', { variant: 'success', autoHideDuration: 2500 });
          localStorage.setItem('candidateId', res.payload.output.candidateId);
          props.history.push({
            pathname: '/settings',
            state: { disable: true },
            search: '?tab=TrackPage'
          });
        } else {
          enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
        }
      })
      .catch((err: any) => {
        setStatus({ success: false });
        setErrors(err.message);
        setSubmitting(false);
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        });
      });
  };

  return (
    <Formik
      initialValues={{ fullname: '', email: '', password: '', confirmPassword: '', market: 'INTERVIEW_HELP' }}
      validationSchema={Yup.object({
        fullname: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        market: Yup.string().required('Required'),
        password: Yup.string().min(6, 'Must be 6 characters or more').required('Required'),
        confirmPassword: Yup.string()
          .required('Required')
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
          }),
      })}
      onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        handleSignup(values, { setSubmitting, setErrors, setStatus, resetForm });
      }}
    >
      {({ values, handleChange }) => (
        <SignupContainer>
          <Form>
            <h1 className="text--left text--30">Create your account</h1>
            <Field name="fullname" type="text" placeholder="Full Name" component={TextField} />
            <Field name="email" type="email" placeholder="Email or Phone" component={TextField} />
            <Field name="password" placeholder="Password" component={PasswordField} />
            <Field name="confirmPassword" placeholder="Verify Password" component={PasswordField} />
            {!isProd() && marketList && (
              <MarketSelectDropdown marketList={marketList} handleChange={handleChange} value={values.market} />
            )}
            <Button type="submit" className="bg--purple text--bold text--white mt--30">
              Create your account
            </Button>
          </Form>
        </SignupContainer>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  createNewCandidateForMarketAction,
};

const mapStateToProps = (state: any) => ({
  marketList: state.evaluationPlatform.marketList,
});

export const Signup = connect(mapStateToProps, mapDispatchToProps)(_Signup);
