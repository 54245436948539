import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const modalStyle: Modal.Styles | undefined = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.44)',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    position: 'fixed',
  },
  content: {
    position: 'absolute',
    background: 'white',
    borderRadius: '10px',
    padding: '1em 2em',
    margin: 'auto',
    width: '40%',
    height: 'fit-content',
    overflow: 'none',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  },
}

const CardDetail = styled.div`
  .price-info {
    display: flex;
    justify-content: space-between;
  }
`;

export const PaymentMethodsModal = ({ open, onClose, cards, amount, onSelect }: IProps) => {
  const [paymentMethodId, setPaymentMethodId] = useState('new');

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodId(e.target.value);
  }

  const onSubmitHandler = () => {
    onSelect(paymentMethodId);
    onClose();
  }

  return (
    <Modal isOpen={open}
      onRequestClose={onClose}
      style={modalStyle}>
      <h5>Payment Methods</h5>
      <hr />
      <CardDetail>
        <div className="price-info">
          <div><h6>{'Price'}</h6></div>
          <div>{amount}</div>
        </div>
        <div>
          <h6>Pay With</h6>
        </div>
        <form onSubmit={onSubmitHandler}>
          {cards.map((card: any, index: any) => {
            return (
              <div key={'pay' + index}>
                <label htmlFor={'payment-method' + index}>{'XXXX-XXXX-XXXX-' + card.last4}: </label>
                <input
                  type="radio"
                  id={'payment-method' + index}
                  name="payment-method"
                  value={card.id}
                  checked={paymentMethodId == card.id}
                  onChange={handleRadioChange} />
                <br />
              </div>
            )
          })}
          <label htmlFor={'payment-methodnew'}>{'Other card'}: </label>
          <input type="radio"
            name="payment-method"
            id={'payment-methodnew'}
            value={'new'}
            checked={paymentMethodId == 'new'}
            onChange={handleRadioChange} />
          <br /><button type="submit">Pay</button>
        </form>
      </CardDetail>
    </Modal>
  );
}

interface IProps {
  open: boolean;
  onClose: any;
  cards: any[];
  amount: string;
  onSelect: (paymentMethodId: string) => void;
}