import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './utilities';
import { SnackbarProvider } from 'notistack';
import store from './store';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback, { errorHandler } from 'components/Error/ErrorFallback';
import { GlobalLoader } from 'context/GlobalLoader';
import { MessagePopup } from 'context/messagePopup';


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SnackbarProvider maxSnack={3}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
          <GlobalLoader>
            <MessagePopup>
              <App />
            </MessagePopup>
          </GlobalLoader>
        </ErrorBoundary>
      </SnackbarProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
