import { Close } from '@styled-icons/evaicons-solid/Close';
import { StyledLinkText } from 'components';
import { Button } from 'components/Common/Button';
import { useLoader } from 'context/GlobalLoader';
import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { HintsAndSampleSolutionService } from 'services/hintsAndSampleSolutions';
import styled from 'styled-components';
import { DEFAULT_TOKEN } from 'utilities/constants';
import { evalPlanUpgradeMessage } from '../../../../utilities/constants';
import Hints from '../../../Modals/Hints';
import SampleSolutions from '../../../Modals/SampleSolutions';

const StyledSolutionsLink = styled.span`
  padding-left: 1rem;
`;

const modalStyle: Modal.Styles | undefined = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.44)',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    position: 'fixed',
  },
  content: {
    position: 'absolute',
    background: 'white',
    borderRadius: '10px',
    padding: '1rem',
    margin: 'auto',
    width: '60%',
    height: 'fit-content',
    overflow: 'none',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  },
}

const ErrorContent = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 1.5rem 1rem 1rem;
    .head {
      font-weight: 800;
      margin-bottom: 2rem;
    };
    .desc {
      font-weight: 600;
      margin-bottom: 2rem;
    };
    .action .moreBtn{
      border-radius: 10px;
      border: none;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: 600;
      height: auto;
      font-family: inherit;
    };
  }
  }
`;

const CloseButton = styled(Close)`
  width: 20px;
  float: right;
  cursor: pointer;
`;

interface IProps {
  question: any;
  goToPaymentPage: any;
}
const AnswerHelpLinks = (props: IProps) => {
  const loader = useLoader();
  const [isHintsOpen, setIsHintsOpen] = useState(false);
  const [questionHints, setquestionHints] = useState([]);
  const [sampleSolutions, setsampleSolutions] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSampleSolutionsOpen, setIsSampleSolutionsOpen] = useState(false);

  const showSampleSolutions = () => {
    loader.showLoader();
    const payload = getPayload();
    if (payload) HintsAndSampleSolutionService.getSampleSolutionsByQuestion(payload).then(res => {
      if (res.apiMessage == evalPlanUpgradeMessage) {
        setIsErrorModalOpen(true);
      }
      else {
        setsampleSolutions(res.output);
        setIsSampleSolutionsOpen(true);
      }
      loader.hideLoader();
    });
    else loader.hideLoader();
  };

  function toggleSolutionsModal(e: any) {
    setIsSampleSolutionsOpen(!isSampleSolutionsOpen);
  }

  function toggleHintModal(e: any) {
    setIsHintsOpen(!isHintsOpen);
  }

  const showHints = () => {
    loader.showLoader();
    const payload = getPayload();
    if (payload) HintsAndSampleSolutionService.getHintsByQuestion(payload).then(res => {
      if (res.apiMessage == evalPlanUpgradeMessage) {
        setIsErrorModalOpen(true);
      }
      else {
        setquestionHints(res.output);
        setIsHintsOpen(true);
      }
      loader.hideLoader();
    });
    else loader.hideLoader();
  };

  const getPayload = () => {
    const trackId = localStorage.getItem('candidateTrackId');
    const questionId = props.question._id as string;
    if (trackId && questionId) {
      return {
        token: DEFAULT_TOKEN,
        candidateTrackId: trackId,
        questionId: questionId
      }
    }
    else return null;
  }

  return (
    <>
      <div>
        {questionHints.length >= 0 && (
          <StyledLinkText size={20} weight="normal" data-tip="Best Practices" data-for="hintLink">
            <span onClick={showHints}>Hints</span>
          </StyledLinkText>
        )}
        <ReactTooltip id="hintLink" type="dark" />
        {sampleSolutions.length >= 0 && (
          <StyledLinkText size={20} weight="normal" data-tip="Sample Solutions" data-for="solutionLink">
            <StyledSolutionsLink onClick={showSampleSolutions}>Sample Solutions</StyledSolutionsLink>
          </StyledLinkText>
        )}
        <ReactTooltip id="solutionLink" type="dark" />
        <Modal style={modalStyle} isOpen={isHintsOpen} onRequestClose={toggleHintModal} >
          <Hints hints={questionHints} />
        </Modal>
        <Modal
          isOpen={isSampleSolutionsOpen}
          onRequestClose={toggleSolutionsModal}
          style={modalStyle}
        >
          <SampleSolutions sampleSolutions={sampleSolutions} />
        </Modal>
        <div>
          <Modal
            isOpen={isErrorModalOpen}
            onRequestClose={() => setIsErrorModalOpen(!isErrorModalOpen)}
            style={{ ...modalStyle, content: { ...modalStyle.content, width: '30%' } }}>
            <div>
              <CloseButton onClick={() => setIsErrorModalOpen(!isErrorModalOpen)} />
            </div>
            <ErrorContent>
              {/* <div className="head">{'It is time to upgrade'}</div> */}
              <div className="desc">{'You have reached the limit of your current plan. To get an access to more features, you need to choose a higher plan.'}</div>
              <div className="action">
                <Button type="button" onClick={props.goToPaymentPage} className="moreBtn bg--purple text--white text--bold">
                  {'Upgrade'}
                </Button>
              </div>
            </ErrorContent>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AnswerHelpLinks;
