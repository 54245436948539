import React from 'react';
import styled from 'styled-components';

const ChipDiv = styled.div`
    display: inline-block;
    padding: 5px;
    font-size: 10px;
    cursor: default;
    border-radius: 1rem;
    letter-spacing: 2px;
    background-color: ${(props: any) => props.color || '#e0e0e0'};
`;

export const Chip = ({ value, color }: IChips) => {
    return (
        <ChipDiv color={color}>
            <span>{value}</span>
        </ChipDiv>
    );
}

interface IChips {
    value: string;
    color?: string;
}