import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
    trackId: string;
    trackPlan: string;
    trackName: string;
}

const initialState: IInitialState = {
    trackId: '',
    trackPlan: '',
    trackName: ''
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setTrackInfoForPayment(state, action) {
            state.trackId = action.payload.trackId;
            state.trackPlan = action.payload.trackPlan;
            state.trackName = action.payload.trackName;
        }
    },
});

export const {
    setTrackInfoForPayment
} = paymentSlice.actions;
export default paymentSlice.reducer;
