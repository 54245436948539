import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp';
import { useLoader } from 'context/GlobalLoader';
import { useMessagePopup } from 'context/messagePopup';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setTrackInfoForPayment } from 'store/payment';
import styled from 'styled-components';
import { IPaymentMethod, IProductsForCandidate, IProductsInfo } from 'types/Payments';
import { notEmpty } from 'utilities';
import { useStripePayment } from '../../../components/Common/customHooks/stipePayment';
import { PaymentHistory } from './paymentHistory';
import { TrackProducts } from './trackProducts';
import { usePlanProducts } from './usePlanProducts';

const ProductsAndHistory = styled.div`
  padding: 2rem;

  .trackInfo {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
  }

  .trackInfo form {
    display: inline-block;
  }

  .Collapsible {
    margin-bottom: 1rem;
  }

  .Collapsible__trigger .head {
    display: flex;
    padding: 0 0.5rem 0 1rem;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
  }
`;

export const PlanProducts = () => {
  const { getProductForCandidate } = usePlanProducts();
  const { enqueueSnackbar } = useSnackbar();
  const { getAllTakenTracks } = usePlanProducts();
  const [paymentHistory, setPaymentHistory] = useState<any>([]);
  const loader = useLoader();
  const [tracks, setTracks] = useState<any[]>([]);
  const [trackProductInfo, setTrackProductInfo] = useState<IProductsForCandidate | null>(null);
  const [selectedTrackId, setSelectedTrackId] = useState('');
  const { trackId, trackName } = useSelector((state: RootState) => state.payment);
  const dispatch = useDispatch();

  useEffect(() => {
    loadTracks();
  }, []);

  useEffect(() => {
    notEmpty(selectedTrackId) && loadProducts();
    !notEmpty(selectedTrackId) && resetAll();
  }, [selectedTrackId]);

  async function loadTracks() {
    loader.showLoader(true);
    try {
      const tracks = await getAllTakenTracks();
      setTracks(tracks);
      setSelectedTrackId(trackId);
      loader.hideLoader();
    }
    catch {
      loader.hideLoader();
      enqueueSnackbar('Error fetching tracks', {
        variant: 'error',
        autoHideDuration: 2500,
      })
    }
  }

  const TriggerElm = ({ heading, open }: any) => (
    <div className="head">
      <div>
        {heading}
      </div>
      {!open && <ChevronDown width="40px" />}
      {open && <ChevronUp width="40px" />}
    </div>
  )

  const onTrackSelect = (e: any) => {
    const trackId = e.target.value;
    setSelectedTrackId(trackId);
  }

  const loadProducts = () => {
    loader.showLoader();

    getProductForCandidate(selectedTrackId)
      .then(p => {
        setTrackProductInfo(p);
        notEmpty(p.paymentHistory) && setPaymentHistory(p.paymentHistory);
        loader.hideLoader();
      })
      .catch(err => {
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        })
        loader.hideLoader();
      });
  }

  const resetAll = () => {
    setTrackProductInfo(null);
    setPaymentHistory([]);
  }

  const getTrackName = (trackId: string): string => {
    const track = tracks.find(t => t.trackId == trackId);
    return notEmpty(track) ? track.title : '';
  }

  const updateTrackInfoOnPayment = (plan: string) => {
    if (trackId == selectedTrackId) {
      dispatch(setTrackInfoForPayment({
        trackId,
        trackName,
        trackPlan: plan,
      }))
    }
  }

  return (
    <div>
      {/* {!notEmpty(selectedTrackId) && loader.showLoader()} */}
      {notEmpty(selectedTrackId) && (<ProductsAndHistory>
        <div className="trackInfo">
          <span>{'Showing plan and payments for your enrolled track - '}</span>
          {notEmpty(tracks) && tracks.length > 1
            && (<form>
              <select name="track" id="track" onChange={onTrackSelect} value={selectedTrackId}>
                {tracks.map(track => (
                  <option key={track.trackId} value={track.trackId}>{track.title}</option>
                ))}
              </select>
            </form>)}
          {notEmpty(tracks) && tracks.length == 1
            && (<span><b>{tracks[0].title}</b></span>)}
        </div>
        <Collapsible
          trigger={<TriggerElm heading={'Plans'} />}
          triggerWhenOpen={<TriggerElm heading={'Plans'} open={true} />}>
          <TrackProducts
            productInfo={trackProductInfo}
            loadProducts={loadProducts}
            trackId={selectedTrackId}
            trackName={getTrackName(selectedTrackId)}
            updateTrackPlan={updateTrackInfoOnPayment} />
        </Collapsible>
        <Collapsible
          trigger={<TriggerElm heading={'Payments'} />}
          triggerWhenOpen={<TriggerElm heading={'Payments'} open={true} />}>
          <PaymentHistory paymentHistory={paymentHistory} />
        </Collapsible>
      </ProductsAndHistory>)}
    </div>
  );
};