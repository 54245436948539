import { Button } from 'components';
import { Chip } from 'components/Common/Chips';
import { CalendlyPopup } from 'components/Modals/CalendlyPopup';
import { PaymentMethodsModal } from 'components/Modals/PaymentMethodModal';
import { useLoader } from 'context/GlobalLoader';
import { useMessagePopup } from 'context/messagePopup';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IPaymentMethod, IPlan, IProductsForCandidate, IProductsInfo } from 'types/Payments';
import { notEmpty } from 'utilities';
import { useStripePayment } from '../../../components/Common/customHooks/stipePayment';
import { usePlanProducts } from './usePlanProducts';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';

const PlanProductsDiv = styled.div`
  text-align: left;
  padding: 2rem 10rem;
  
  .product {
    margin: 0 auto;
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    .currentPlan {
        min-width: 160px;
    }
    .currentPlanState {
        min-width: 160px;
    }
  }

  .product-info {   
    display: flex;
    overflow: hidden;

        .product-type {
            width: 30%;
            align-items: center;
            justify-content: center;
            display: flex;
            color: #5b94e3;
            font-size: 20px;
            font-weight: bold;
            padding-left: 1rem;
        }

        .product-details {
            width: 70%;
            padding: 1rem;

            button:disabled {
                background: #D5D8DC;
            }

            button:hover:enabled {
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
                transition: 0.3s;
            }

            .price {
                color: #5b94e3;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }
`;

interface ISelectedProductInfo {
    productId: string;
    price: string;
}

interface ICurrentBoughtProduct {
    id: string;
    planState: string;
}

export const TrackProducts = ({ productInfo, loadProducts, trackId, trackName, updateTrackPlan }: IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { proceedToCheckout, getPaymentMethods, doDirectPayment } = usePlanProducts();
    const [planProductsList, setPlanProductsList] = useState<IProductsInfo[]>([]);
    const [currentBoughtProduct, setCurrentBoughtProduct] = useState<ICurrentBoughtProduct>({ id: '', planState: '' });
    const { startCheckout } = useStripePayment();
    const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
    const [savedCards, setSavedCards] = useState<IPaymentMethod[]>([]);
    const [selectedProductInfo, setSelectedProductInfo] = useState<ISelectedProductInfo | null>(null);
    const loader = useLoader();
    const message = useMessagePopup();
    const [openCalendly, setOpenCalendly] = useState(false);

    useEffect(() => {
        if (notEmpty(productInfo)) {
            modifyProducts(productInfo);
        }
        else {
            resetAll();
        }
    }, [productInfo]);

    const modifyProducts = (p: any) => {
        if (p && p.productInfo && p.productInfo.length > 0) {
            let modifiedProductList = manipulateProductsList(p.productInfo, p.plan);
            setPlanProductsList(modifiedProductList);
            if ((!notEmpty(p.plan.plan) || p.plan.plan == "noplan")) {
                let id = modifiedProductList.find(x => x.isFree)?.id;
                setCurrentBoughtProduct({ id: id ? id : '', planState: '' });
            }
            else if (notEmpty(p.plan.plan)) {
                setCurrentBoughtProduct({ id: p.plan.plan, planState: p.plan.planState });
            }
        }
        else {
            resetAll();
        }
    }

    const resetAll = () => {
        setPlanProductsList([]);
        setCurrentBoughtProduct({ id: '', planState: '' });
        setSavedCards([]);
        setSelectedProductInfo(null);
    }


    async function onPaymentMethodSelect(paymentMethodId: string) {
        if (paymentMethodId == "new") {
            selectedProductInfo && continueCheckout(selectedProductInfo.productId as string);
        } else if (notEmpty(paymentMethodId)) {
            await makePayment(paymentMethodId);
        }
    }

    async function makePayment(paymentMethodId: string | null) {
        loader.showLoader();
        try {
            if (selectedProductInfo) {
                const checkout = await doDirectPayment(paymentMethodId, selectedProductInfo.productId, trackId);
                if (checkout.status === "200") {
                    if (isContract(selectedProductInfo.productId) || (notEmpty(checkout.paymentData) && checkout.paymentData[0]?.paid && checkout.paymentData[0]?.status == "succeeded")) { }
                    loader.hideLoader();
                    message.success();
                    updatePlan();
                    loadProducts();
                }
                else {
                    loader.hideLoader();
                    message.fail();
                }
            }
        }
        catch (err) {
            loader.hideLoader();
            enqueueSnackbar('Server Error', {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    }

    const clearSelectedProductInfo = () => {
        setSelectedProductInfo(null);
    }

    const bookAppointment = (productId: string, price: string) => {
        setSelectedProductInfo({
            productId: productId,
            price: '$' + price
        });

        setOpenCalendly(true);
    }

    async function onAppointmentBooked(e: any) {
        if (e.data.event === "calendly.event_scheduled") {
            setOpenCalendly(false);
            enqueueSnackbar('Meeting booked', {
                variant: 'success',
                autoHideDuration: 2500,
            });
            await makePayment(null);
        }
    }

    const manipulateProductsList = (list: IProductsInfo[], plan: IPlan): IProductsInfo[] => {
        return list.sort((a, b) => a.order - b.order)
            .map(x => {
                x.price = x.price / 100;
                return x;
            });
    }

    async function buyProduct(productId: string, price: string) {
        loader.showLoader();
        try {
            const paymentMethods = await getPaymentMethods();
            if (notEmpty(paymentMethods)) {
                setSavedCards(paymentMethods);
                setSelectedProductInfo({
                    productId: productId,
                    price: '$' + price
                });
                setOpenPaymentMethods(true);
            }
            else {
                await continueCheckout(productId);
            }
            loader.hideLoader();
        }
        catch (err) {
            loader.hideLoader();
            enqueueSnackbar(err, {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    }

    async function continueCheckout(productId: string) {
        loader.showLoader();
        try {
            const sessionRes = await proceedToCheckout(productId as string, trackId);
            startCheckout(sessionRes.sessionId);
        }
        catch (err) {
            loader.hideLoader();
            enqueueSnackbar(err, {
                variant: 'error',
                autoHideDuration: 2500,
            });
        }
    }

    const getProduct = (productId: string): IProductsInfo | undefined => {
        return planProductsList.find(p => p.id == productId);
    }

    const getProductType = (product: IProductsInfo) => {
        if (product.isFree) return "Free";
        if (product.isContractProduct) return "Unlimited";
        if (product.isSubscriptionProduct) return "Subscription";
    }

    const isContract = (productId: string): boolean => {
        return !!(getProduct(productId))?.isContractProduct;
    }

    const updatePlan = () => {
        if (selectedProductInfo) {
            const product = getProduct(selectedProductInfo.productId);
            if (product) {
                updateTrackPlan(getProductType(product));
            }
        }
    }

    return (
        <PlanProductsDiv>
            {planProductsList.map(product => (
                <div key={product.id} className="product">
                    <div className="currentPlan">
                        {currentBoughtProduct.id == product.id && <div>Your current plan<RightArrowAlt color="#000" width="30px" /></div>}
                    </div>
                    <div className="product-info card-ih">
                        <div className="product-type">
                            <div>
                                <div>
                                    {getProductType(product) + ' Plan'}
                                </div>
                                {currentBoughtProduct.id == product.id
                                    && <Chip value={notEmpty(currentBoughtProduct?.planState) ? currentBoughtProduct.planState : 'ACTIVE'} color="#ABEBC6" />}
                            </div>
                        </div>
                        <div className="product-details">
                            <div className="product-name">
                                <h4>{product.displayName}</h4>
                            </div>
                            <div className="product-desc">
                                <div>{product.description}</div>
                                <hr />
                                <div>{product.displayDescription}</div>
                            </div>
                            <div className="mt-3">
                                {!product.isFree && <div className="price">{'$'}{product.price.toFixed(2)}</div>}
                                {!product.isFree && !product.isContractProduct &&
                                    <div>
                                        <button type="button"
                                            onClick={async () => await buyProduct(product.id, product.price.toFixed(2))}
                                            className="text--white text--bold payment-btn"
                                            disabled={currentBoughtProduct.id == product.id || isContract(currentBoughtProduct.id)}>
                                            {'Buy'}
                                        </button>
                                    </div>}
                                {product.isContractProduct &&
                                    <button type="button"
                                        onClick={() => bookAppointment(product.id, product.price.toFixed(2))}
                                        className="text--white text--bold payment-btn"
                                        disabled={currentBoughtProduct.id == product.id}>
                                        {'Buy'}
                                    </button>}
                            </div>
                        </div>
                    </div>
                    <div className="currentPlanState">
                        {currentBoughtProduct.id == product.id
                            && product.isContractProduct
                            && <div>Meeting in progress</div>}
                    </div>
                </div>
            ))}
            {selectedProductInfo && <PaymentMethodsModal
                open={openPaymentMethods}
                cards={savedCards}
                amount={selectedProductInfo.price}
                onClose={() => {
                    clearSelectedProductInfo();
                    setOpenPaymentMethods(false)
                }
                }
                onSelect={onPaymentMethodSelect}
            />}
            <CalendlyPopup
                open={openCalendly}
                onClose={() => setOpenCalendly(false)}
                onConfirm={async (e: any) => await onAppointmentBooked(e)}
                customAnswers={{ a1: `Setting up a meeting for track - ${trackName}` }} />
        </PlanProductsDiv >
    )
}

interface IProps {
    productInfo: IProductsForCandidate | null;
    loadProducts: Function;
    trackId: string;
    trackName: string;
    updateTrackPlan: Function;
}
