import React from 'react';
import { Settings } from '@styled-icons/material/Settings';
import styled from 'styled-components';

const StyledIcon = styled(Settings)`
  color: #5b94e3;
  width: 100px;
  margin-bottom: 1rem;
`;

const SettingsDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    table {
        margin: 0 auto;
    }

    td, th {
        border-left: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    td:first-child {
        border: none;
    }
`;

export const SettingsDescriptionPage = () => {

    return (
        <SettingsDescription>
            <StyledIcon />
            <table>
                <tbody>
                    <tr>
                        <td><b>{'Manage Track'}</b></td>
                        <td>{'Enroll yourself in new tracks'}</td>
                        <td>{'Change your current track'}</td>
                    </tr>
                    <tr>
                        <td><b>{'Plan & Payments'}</b></td>
                        <td>{'See your current plan'}</td>
                        <td>{'Upgrade your plan'}</td>
                        <td>{'See your payment history'}</td>
                    </tr>
                    <tr>
                        <td><b>{'Others'}</b></td>
                        <td>{'Coming soon...'}</td>
                    </tr>
                </tbody>
            </table>
        </SettingsDescription>
    )
}