import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router';
import {
  LoginPage,
  SignupPage,
  QuestionPage,
  TrackPage,
  NotFoundPage,
  ForgotPasswordPage,
  DashboardPage,
  VideosPage,
  SettingsPage,
  ResetPasswordPage,
} from './pages';
import { connect } from 'react-redux';
import { getMarketInfoAction, getMarketListAction } from 'store/evaluationPlatform';
import { DEFAULT_MARKET_NAME, DEFAULT_TOKEN } from 'utilities/constants';
import './App.css';
import { SuccessPaymentPage } from 'pages/PaymentPage/successPage';
import { FailurePaymentPage } from 'pages/PaymentPage/failurePage';
import { PrivateRoute } from 'utilities/routers/PrivateRoute';
import { PublicRoute } from 'utilities/routers/PublicRoute';

const App = (props: any) => {
  useEffect(() => {
    props.getMarketInfoAction({ token: DEFAULT_TOKEN, market: DEFAULT_MARKET_NAME });
    props.getMarketListAction({ types: ['Market'] });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="App">
      <Switch>
        <PublicRoute path="/" component={LoginPage} exact />
        <PublicRoute path="/signup" component={SignupPage} exact />
        <PublicRoute path="/forgot-password" component={ForgotPasswordPage} exact />
        <PublicRoute path="/resetPassword" component={ResetPasswordPage} exact />
        <PrivateRoute path="/videos" component={VideosPage} exact />
        <PrivateRoute path="/settings" component={SettingsPage} exact />
        <PrivateRoute path="/dashboard" component={DashboardPage} exact />
        <PrivateRoute path="/question" component={QuestionPage} exact />
        <PrivateRoute path="/tracks" component={TrackPage} exact />
        <Route path="/payment-success" component={SuccessPaymentPage} exact />
        <Route path="/payment-fail" component={FailurePaymentPage} exact />
        <PublicRoute component={NotFoundPage} />
      </Switch>
    </div>
  );
};

const mapDispatchToProps = {
  getMarketInfoAction,
  getMarketListAction,
};

export default connect(null, mapDispatchToProps)(App);
