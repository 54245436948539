import React, { useState, useEffect } from 'react';
import { Sidebar, MainHeader, TreeSidebar, QuestionContainer } from 'containers';
import { get } from 'lodash';
import { css } from '@emotion/core';
import { connect, useDispatch } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  setCapability,
  saveResponseForQuestionOfCandidateTrack,
  setAnswer,
  setQuestionId,
  submitResponseToExpert,
  setFeedback,
  setAllFeedbacks,
  setQuestionFeedbackViewed,
  candidateViewedExpertFeedback,
  getDetailsForCandidatebyCandidateTrackId,
  getAnswerStatus,
  setCurrentAnsVersionId,
  saveCandidateLastActivity,
  setScrollPosition
} from 'store/evaluationPlatform';
import { DEFAULT_TOKEN, DEFAULT_GET_ANS_STATUS_TIME, Expert_Session_Id } from 'utilities/constants';
import Modal from 'react-modal';
import styled from 'styled-components';
import { IGetAnswerStatusRequest, IQuestionAnswerRequest } from 'types';
import { useSnackbar } from 'notistack';
import { setTrackInfoForPayment } from 'store/payment';
import { notEmpty, post, useQuery } from 'utilities';
import { clearBrowserStorage, getValueBrowserStorage } from 'services/browserStorageService';
import { paymentService } from 'services/payment';

Modal.setAppElement('#root');

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  display: block;
`;

const StyledQuestionPage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
  }
  .main {
    margin-top: 57px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-height: 100%;
  }
`;

const QuestionPage = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const trackName = get(props.currentTrack, 'candidateTrack[0].trackType');
  const candidateTrackId = get(props.currentTrack, 'candidateTrack[0].candidateTrackId');
  const category = get(props.currentTrack, 'candidateTrack[0].category');
  const capability = get(props.currentTrack, 'candidateTrack[0].capabilities');
  let [isMaximizeContent, setMaximizeContent] = useState(false);
  const [triggerFeedback, setTriggerFeedback] = useState<ITriggerFeedback>();
  const dispatch = useDispatch();
  const handleMaximizeContent = () => {
    setMaximizeContent(!isMaximizeContent);
  };

  useEffect(() => {
    const session = {
      sessionId: getValueBrowserStorage(Expert_Session_Id)
    };

    if (notEmpty(session.sessionId)) {
      clearBrowserStorage(Expert_Session_Id);
      paymentService.verifyCheckout(session)
        .then(res => {
          if (res?.message == "Success" && res.status == "200") {
            const jsonRes = JSON.parse(res.response);
            if (jsonRes.payment_status !== "unpaid") {
              setTriggerFeedback({ expertId: jsonRes.metadata.expertId, questionId: jsonRes.metadata.questionId, answerId: jsonRes.metadata.answerId });
            }
            else {
              enqueueSnackbar('Payment failed for expert, please try again', {
                variant: 'error',
                autoHideDuration: 2500,
              });
            }
          }
        })
        .catch(error => {
          props.history.push('/payment-fail');
        });
    }
  }, []);

  useEffect(() => {
    if (props.currentTrack === null) {
      const savedCandidateTrackId = localStorage.getItem('candidateTrackId');
      if (savedCandidateTrackId !== null && savedCandidateTrackId !== 'undefined') {
        props.getDetailsForCandidatebyCandidateTrackId({
          token: DEFAULT_TOKEN,
          candidateTrackId: savedCandidateTrackId,
        });
      }
    }
    else {
      dispatch(setTrackInfoForPayment({
        trackId: get(props.currentTrack, 'candidateTrack[0].trackId'),
        trackPlan: get(props.currentTrack, 'candidateTrack[0].plan'),
        trackName: get(props.currentTrack, 'candidateTrack[0].trackType'),
      }));
    }

    if (capability && capability.length) {
      const interval = setInterval(() => {
        updateAnswerStatus();
      }, DEFAULT_GET_ANS_STATUS_TIME);
      return () => clearInterval(interval);
    }
  }, [capability]);

  function updateAnswerStatus() {
    let questionAnswerRequest: IQuestionAnswerRequest[] = [];
    capability?.forEach((capibility: any) => {
      capibility?.questions?.forEach((question: any) => {
        question?.answers.forEach((answer: any) => {
          if (answer?.feedbacks.length > 0 && (
            answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT' ||
            answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE')) {
            let req: IQuestionAnswerRequest = {
              questionId: answer?.answer.questionId,
              questionAnswerId: answer?.answer._id,
              clientFeedbackStatus: answer?.feedbacks[0]?.feedbackStatus,
              clientQuestionAnswerStatus: getAnswerFeedbackStatus(answer),
            };
            questionAnswerRequest.push(req);
          }
        });
      });
    });

    let payload: IGetAnswerStatusRequest = {
      token: DEFAULT_TOKEN,
      questionAnswerRequest: questionAnswerRequest,
    };
    props
      .getAnswerStatus(payload)
      .then((res: any) => {
        if (res.payload?.apiStatus === 'SUCCESS') {
          props.setAllFeedbacks(res);
        } else {
          enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        });
      });
  }

  const getAnswerFeedbackStatus = (props: any): string => {
    if (props.answer === null || (props.answer && props.answer?.answer === '')) {
      return 'UNANSWERED';
    } else if (props.answer?.answer !== '' && props.feedbacks?.length === 0) {
      return 'ANSWERED';
    } else if (props.feedbacks && props.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT') {
      return 'SEND_FOR_REVIEW';
    } else if (props.feedbacks && props.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE') {
      return 'UNDER_REVIEW';
    } else if (props.feedbacks && props.feedbacks[0]?.feedbackStatus === 'EXPERT_GIVES_FEEDBACK') {
      return 'FEEDBACK_RECEIVED';
    } else if (props.feedbacks && props.feedbacks[0]?.feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE') {
      return 'FEEDBACK_VIEWED_BY_CANDIDATE';
    }
    return 'UNANSWERED';
  };

  const goToPaymentPage = () => {
    props.history.push({
      pathname: '/settings',
      search: '?tab=PaymentPage'
    });
  }

  return (
    <StyledQuestionPage>
      <Sidebar isMaximizeContent={isMaximizeContent} />

      <div className="content">
        <div className="header">
          <MainHeader
            isMaximizeContent={isMaximizeContent}
            handleMaximizeContent={handleMaximizeContent}
            color="#315cd5"
          />
        </div>
        <div className="main">
          <TreeSidebar
            setScrollPosition={props.setScrollPosition}
            isMaximizeContent={isMaximizeContent}
            trackName={trackName}
            category={category}
            setCapability={props.setCapability}
          />
          {props.loading ? (
            <BeatLoader css={override} color={'#3694D7'} loading={props.loading} />
          ) : (
              <QuestionContainer
                isMaximizeContent={isMaximizeContent}
                candidateTrackId={candidateTrackId}
                capability={capability}
                handleMaximizeContent={handleMaximizeContent}
                setCapability={props.setCapability}
                selectedCapabilityId={props.selectedCapabilityId}
                setAnswer={props.setAnswer}
                saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
                Modal={Modal}
                setQuestionId={props.setQuestionId}
                currentQuestionId={props.currentQuestionId}
                submitResponseToExpert={props.submitResponseToExpert}
                setFeedback={props.setFeedback}
                setQuestionFeedbackViewed={props.setQuestionFeedbackViewed}
                candidateViewedExpertFeedback={props.candidateViewedExpertFeedback}
                candidate={props.candidate}
                setCurrentAnsVersionId={props.setCurrentAnsVersionId}
                saveCandidateLastActivity={props.saveCandidateLastActivity}
                goToPaymentPage={goToPaymentPage}
                triggerFeedback={triggerFeedback}
              />
            )}
        </div>
      </div>
    </StyledQuestionPage>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.evaluationPlatform.loading,
  currentTrack: state.evaluationPlatform.currentTrack,
  selectedCapabilityId: state.evaluationPlatform.selectedCapabilityId,
  currentQuestionId: state.evaluationPlatform.currentQuestionId,
  candidate: state.evaluationPlatform.candidate,
});

const mapDispatchToProps = {
  setAnswer,
  setCapability,
  setQuestionId,
  saveResponseForQuestionOfCandidateTrack,
  submitResponseToExpert,
  setFeedback,
  setAllFeedbacks,
  setQuestionFeedbackViewed,
  candidateViewedExpertFeedback,
  getDetailsForCandidatebyCandidateTrackId,
  getAnswerStatus,
  setCurrentAnsVersionId,
  saveCandidateLastActivity,
  setScrollPosition,
};

interface ITriggerFeedback {
  questionId: string;
  expertId: string;
  answerId: string;
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
