import { RoundButton } from 'components';
import { useLoader } from 'context/GlobalLoader';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DEFAULT_TOKEN, QUESTION_STATUS_TEXT } from 'utilities/constants';
import { getDateTimeInLocalFormat, getExpertName, notEmpty } from 'utilities/helperFunctions';
import { ExpertSelectionModal } from './ExpertSelectionModal';

const StyledAnswerBottom = styled.div`
  display: flex;
  flex-direction: column;
  .answer-buttonarea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button:focus{
      outline: none;
      border: black 2px solid;
    }
    margin-top: 32px;
    .save-button-inside {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .save-button-inside span {
      margin-right: 5px;
    }
  }
  .answer-statusarea {
    margin-top: 30px;
    font-family: Khula;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: #5f5f5f;
  }
`;

interface IProps {
  id: string;
  candidateTrackId: string;
  capabilityId: string;
  questionId: string;
  answer: any;
  questionStatusText: string;
  setQuestionStatusText: any;
  submitResponseToExpert: any;
  question: any;
  setFeedback: any;
  answerStatus: string;
  triggerFeedback: any;
}

interface IAnsStatusTxtProps {
  questionStatusText: string;
}


const StatusText = (props: IAnsStatusTxtProps) => {
  return (
    <div className="answer-statusarea" data-testid="answerfield-statustext">
      {
        `${props.questionStatusText}`
      }&nbsp;
    </div>
  );
};

const QuestionButtons = (props: IProps) => {
  const loader = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [isShowChooseExpert, setShowChooseExpert] = useState(false);

  useEffect(() => {
    if (notEmpty(props.triggerFeedback)
      && props.triggerFeedback.questionId == props.questionId
      && props.triggerFeedback.answerId == props.answer.answer._id) {
      submitFeedbackToExpert(props.triggerFeedback.expertId);
    }
  }, [props.triggerFeedback]);

  const submitFeedbackToExpert = (expertId: string) => {
    loader.showLoader();
    const capabilityIds: string[] =
      props.answer?.answer && props.answer.answer?.capabilityIds ? [...props.answer.answer.capabilityIds] : [];
    if (capabilityIds.indexOf(props.capabilityId) < 0) capabilityIds.push(props.capabilityId);
    const payload = {
      token: DEFAULT_TOKEN,
      candidateTrackId: props.candidateTrackId,
      capabilityIds,
      questionId: props.questionId,
      questionAnswerId: props.answer?.answer._id,
      expertId,
    };
    props.setQuestionStatusText('Saving...')
    props
      .submitResponseToExpert(payload)
      .then((res: any) => {
        if (res.payload) {
          props.setFeedback({
            ...res.payload.output,
            expertId,
            capabilities: props.question?.capabilities,
          });
          props.setQuestionStatusText('Submitted for Review');
          enqueueSnackbar("Answer submitted to expert", {
            variant: 'success',
            autoHideDuration: 2500,
          });
        } else {
          enqueueSnackbar(res.error?.message, {
            variant: 'error',
            autoHideDuration: 2500,
          });
        }
        loader.hideLoader();
      })
      .catch((err: any) => {
        enqueueSnackbar(err?.message, {
          variant: 'error',
          autoHideDuration: 2500,
        });
        loader.hideLoader();
      }
      );
  }

  return (
    <div>
      <ExpertSelectionModal
        isShowChooseExpert={isShowChooseExpert}
        setShowChooseExpert={setShowChooseExpert}
        questionId={props.questionId}
        answerId={props.answer.answer._id}
        submitFeedbackToExpert={submitFeedbackToExpert} />
      <div className="answer-buttonarea">
        <RoundButton onClick={() => setShowChooseExpert(true)}>Submit for feedback</RoundButton>
      </div>
    </div>
  );
};

export const AnswerBottom: React.FC<IProps> = (props) => {
  const expertName = getExpertName(props.answer?.feedbacks[0]?.expertId)
  const ansDate = getDateTimeInLocalFormat(props?.answer?.answer?.updateAt);
  const feedbackDate = getDateTimeInLocalFormat(props?.answer?.feedbacks[0]?.feedbackAt);
  useEffect(() => {
    if (props.answerStatus === QUESTION_STATUS_TEXT.SUBMITTED_FOR_REVIEW) {
      props.setQuestionStatusText(`Submitted for Review ${expertName ? `to ${expertName} ` : ''} on ${ansDate}`);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.UNDER_REVIEW) {
      props.setQuestionStatusText(`Your response is under review by ${expertName}`);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.FEEDBACK_VIEWED) {
      props.setQuestionStatusText(`You responded on ${ansDate}, feedback received on ${feedbackDate}, provided by ${expertName}`);
    } else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
      props.setQuestionStatusText(`Saved successfully at ${ansDate}`);
    } if (props.answerStatus === QUESTION_STATUS_TEXT.UNANSWERED) {
      props.setQuestionStatusText('');
    }
    // else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
    // else if (props.answerStatus === QUESTION_STATUS_TEXT.ANSWERED) {
    //   props.setQuestionStatusText('Your answer is under review');
    // }
    // eslint-disable-next-line
  }, [props.answerStatus, props.answer]);

  return (
    <StyledAnswerBottom>
      <StatusText questionStatusText={props.questionStatusText} />
      {props.answerStatus === 'UNANSWERED' || (props.answerStatus === 'ANSWERED' && <QuestionButtons {...props} />)}
    </StyledAnswerBottom>
  );
};
