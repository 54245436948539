import React, { useEffect, useState } from 'react';
import { ListIcon, YoutubeIcon } from 'theme/icons';
// import DashboardIcon from 'assets/icons/outerSidebar/dashboard.png';
import { IconButton } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { Settings } from '@styled-icons/material/Settings';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { notEmpty } from 'utilities';

enum ActiveButtonStates {
  DASHBOARD = 1,
  QUESTION = 2,
  VIDEOS = 3,
  SETTINGS = 4
}

const StyledDashboardIcon = styled(Dashboard)`
  width: 35px;
  height: auto;
`;

const StyledSettings = styled(Settings)`
  width: 38px;
  height: auto;
`;

const StyledSidebar = styled.div<any>`
  position: fixed;
  display: flex;
  opacity: ${(props) => (props.isMaximizeContent ? 0 : 1)};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 78px;
  transition: opacity 1s;
  height: 100%;
  background: #dbdbdb;
  div {
    background: #dbdbdb;
  }
  .profileIcon {
    margin-top: 32px;
  }
  .profileIcon div {
    background: white;
    border-radius: 50%;
    width: 43px;
    height: 43px;
  }
  .dashboardIconContainer {
    margin-top: 80px;
  }
  .dashboardText {
    font-size: 12px;
  }
  .tracksIcon {
    margin-top: 32px;
  }
  .videoIcon {
    margin-top: 32px;
  }
  .settingsIcon {
    margin-bottom: 30px;
  }
  & a:hover path {
    fill: ${(props) => (!props.disable ? '#5b94e3 ' : 'initial')};
  }
  
  & a:hover .iconText {
    color: ${(props) => (!props.disable ? '#5b94e3 ' : 'initial')};
  }
  & a {
    text-decoration: none;
    color: black;
    pointer: ${(props) => (props.disable ? 'none' : 'pointer')};
    pointer-events: ${(props) => (props.disable ? 'none' : 'unset')};
  }
`;

interface Props {
  className?: string;
  isMaximizeContent?: boolean;
  disable?: boolean;
}

const TopIconButtonGroup = styled.div`
  & a {
    text-decoration: none;
  }
`;

// const ProfileButton = () => (
//   <div className="profileIcon">
//     <div />
//   </div>
// );

const DashboardButton = (props: any) => {
  const content = (
    <div className="dashboardIconContainer">
      <IconButton icon={StyledDashboardIcon} iconText="Dashboard" disable={props.disable} fill={props.color} />
    </div>
  )
  return props.disable ? content : <Link to={props.disable ? '#' : '/dashboard'}>{content}</Link>
};

const TracksButton = (props: any) => {
  const content = (
    <div className="tracksIcon">
      <IconButton icon={ListIcon} iconText="Track" disable={props.disable} fill={props.color} />
    </div>
  );

  return props.disable ? content : <Link to={props.disable ? '#' : '/question'}>{content}</Link>
};

const VideoButton = (props: any) => {
  const content = (
    <div className="videoIcon">
      <IconButton icon={YoutubeIcon} iconText="Videos" disable={props.disable} fill={props.color} />
    </div>
  )

  return props.disable ? content : <Link to={props.disable ? '#' : '/videos'}>{content}</Link>
};

const SettingsButton = (props: any) => (
  <div className="settingsIcon">
    <Link to={props.disable ? '/settings?tab=TrackPage' : '/settings'}>
      <IconButton icon={StyledSettings} iconText="Settings" fill={props.color} />
    </Link>
  </div>
);

export const Sidebar: React.FC<Props> = (props) => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState<ActiveButtonStates>(ActiveButtonStates.SETTINGS);
  const { trackId } = useSelector((state: RootState) => state.payment);

  useEffect(() => {
    setActiveButtonState();
  }, []);

  const setActiveButtonState = () => {
    switch (location.pathname) {
      case '/dashboard':
        setActiveButton(ActiveButtonStates.DASHBOARD);
        break;
      case '/question':
        setActiveButton(ActiveButtonStates.QUESTION);
        break;
      case '/videos':
        setActiveButton(ActiveButtonStates.VIDEOS);
        break;
      case '/settings':
        setActiveButton(ActiveButtonStates.SETTINGS);
        break;
    }
  }

  const getActiveColor = (state: ActiveButtonStates): string => {
    return activeButton == state ? '#5b94e3' : 'default';
  }

  return (
    <StyledSidebar {...props}>
      <TopIconButtonGroup>
        {/* <ProfileButton /> */}
        <DashboardButton disable={!notEmpty(trackId)} color={getActiveColor(ActiveButtonStates.DASHBOARD)} />
        <TracksButton disable={!notEmpty(trackId)} color={getActiveColor(ActiveButtonStates.QUESTION)} />
        <VideoButton disable={!notEmpty(trackId)} color={getActiveColor(ActiveButtonStates.VIDEOS)} />
      </TopIconButtonGroup>
      <SettingsButton disable={!notEmpty(trackId)} color={getActiveColor(ActiveButtonStates.SETTINGS)} />
    </StyledSidebar>
  );
};
