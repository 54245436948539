import { AnswerBottom, AnswerField, FeedbackField, MetricsField } from 'components';
import _ from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledAnswerSet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    margin-bottom: 18px;
  }
`;

interface IProps {
  idx: number;
  candidateTrackId: string;
  capabilityId: string;
  questionId: string;
  answer: any;
  handleMaximizeContent?: any;
  isMaximizeContent: boolean;
  setAnswer: any;
  saveResponseForQuestionOfCandidateTrack: any;
  isSaved: boolean;
  setIsSaved: any;
  questionStatusText: string;
  setQuestionStatusText: any;
  submitResponseToExpert: any;
  question: any;
  setFeedback: any;
  answerVersion: number;
  setQuestionId: Function,
  triggerFeedback: any;
}

const getAnswerFeedbackStatus = (props: any): string => {
  if (props.answer === null || (props.answer && props.answer?.answer?.answer === '' && props.answer?.answer?.codeAnswer === '')) {
    return 'UNANSWERED';
  } else if ((props.answer?.answer?.answer !== '' || props.answer?.answer?.codeAnswer !== '') && props.answer?.feedbacks?.length === 0) {
    return 'ANSWERED';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT') {
    return 'SEND_FOR_REVIEW';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE') {
    return 'UNDER_REVIEW';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_GIVES_FEEDBACK') {
    return 'FEEDBACK_RECEIVED';
  } else if (props.answer?.feedbacks && props.answer?.feedbacks[0]?.feedbackStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE') {
    return 'FEEDBACK_VIEWED_BY_CANDIDATE';
  }
  return 'UNANSWERED';
};

export const AnswerSet: React.FC<IProps> = (props) => {
  let answerStatus = getAnswerFeedbackStatus(props);
  let feedbackIdx = _.findIndex(props.answer?.feedbacks, (feedbackItem: any) => {
    return feedbackItem.questionAnswerId === props.answer?.answer?._id;
  });
  let feedback = feedbackIdx > -1 && props.answer?.feedbacks[feedbackIdx];
  useEffect(() => {
    answerStatus = getAnswerFeedbackStatus(props);
    feedbackIdx = _.findIndex(props.answer?.feedbacks, (feedbackItem: any) => {
      return feedbackItem.questionAnswerId === props.answer?.answer?._id;
    });
    feedback = feedbackIdx > -1 && props.answer?.feedbacks[feedbackIdx];
    // eslint-disable-next-line
    // eslint-disable-next-line
  }, [props.answer]);
  return (
    <StyledAnswerSet>
      <AnswerField
        textId={`${props.questionId}-${props.idx}`}
        handleMaximizeContent={props.handleMaximizeContent}
        isMaximizeContent={props.isMaximizeContent}
        candidateTrackId={props.candidateTrackId}
        capabilityId={props.capabilityId}
        questionId={props.questionId}
        answer={props.answer}
        setAnswer={props.setAnswer}
        saveResponseForQuestionOfCandidateTrack={props.saveResponseForQuestionOfCandidateTrack}
        isSaved={props.isSaved}
        setIsSaved={props.setIsSaved}
        questionStatusText={props.questionStatusText}
        setQuestionStatusText={props.setQuestionStatusText}
        question={props.question}
        answerStatus={answerStatus}
        answerVersion={props.answerVersion}
        setQuestionId={props.setQuestionId}
      />
      {answerStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE' && <FeedbackField feedback={feedback} />}
      {answerStatus === 'FEEDBACK_VIEWED_BY_CANDIDATE' && (
        <MetricsField feedback={feedback} capabilityId={props.capabilityId} />
      )}
      <AnswerBottom
        id={`${props.questionId}-${props.idx}`}
        candidateTrackId={props.candidateTrackId}
        capabilityId={props.capabilityId}
        questionId={props.questionId}
        answer={props.answer}
        questionStatusText={props.questionStatusText}
        setQuestionStatusText={props.setQuestionStatusText}
        submitResponseToExpert={props.submitResponseToExpert}
        question={props.question}
        setFeedback={props.setFeedback}
        answerStatus={answerStatus}
        triggerFeedback={props.triggerFeedback}
      />
    </StyledAnswerSet>
  );
};
