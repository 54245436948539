import { post } from '../utilities';
import {
  IGetMarketInfoRequest,
  ICreateNewCandidateForMarketRequest,
  ICreateCandidateTrackForCandidateRequest,
  IGetDetailsForCandidatebyCandidateTrackIdRequest,
  IGetTracksForCandidateRequest,
  ILogin,
  ISaveResponseForQuestionOfCandidateTracks,
  ISubmitResponseToExpert,
  ICandidateViewedExpertFeedback,
  IGetAnswerStatusRequest,
  IForgotPassword,
  IResetPassword,
  ISaveCandidateLastActivity,
  ILogClientErrors,
  IGetCandidateInfoById,
  IGetDashboardRequest,
  IGetStatusScoreRequest
} from '../types';

const API_PREFIX = 'evaluationPlatform';

const getMarketInfo = (payload: IGetMarketInfoRequest) => {
  return post(`${API_PREFIX}/getMarketInfo`, payload);
};

const getEnums = (payload: any) => {
  return post(`metadata/getEnums`, payload);
};

const createNewCandidateForMarket = (payload: ICreateNewCandidateForMarketRequest) => {
  return post(`${API_PREFIX}/createNewCandidateForMarket`, payload);
};

const createCandidateTrackForCandidate = (payload: ICreateCandidateTrackForCandidateRequest) => {
  return post(`${API_PREFIX}/createCandidateTrackForCandidate`, payload);
};

const getDetailsForCandidatebyCandidateTrackId = (payload: IGetDetailsForCandidatebyCandidateTrackIdRequest) => {
  return post(`${API_PREFIX}/getDetailsForCandidatebyCandidateTrackId`, payload);
};

const getTracksForCandidate = (payload: IGetTracksForCandidateRequest) => {
  return post(`${API_PREFIX}/getTracksForCandidate`, payload);
};

const login = (payload: ILogin) => {
  return post(`${API_PREFIX}/login`, payload);
};

const saveResponseForQuestionOfCandidateTrack = (payload: ISaveResponseForQuestionOfCandidateTracks) => {
  return post(`${API_PREFIX}/saveResponseForQuestionOfCandidateTrack`, payload);
};

const submitResponseToExpert = (payload: ISubmitResponseToExpert) => {
  return post(`${API_PREFIX}/submitResponseToExpert`, payload);
};

const candidateViewedExpertFeedback = (payload: ICandidateViewedExpertFeedback) => {
  return post(`${API_PREFIX}/candidateViewedExpertFeedback`, payload);
};

const getAnswerStatus = (payload: IGetAnswerStatusRequest) => {
  return post(`${API_PREFIX}/getAnswerStatus`, payload);
}

const forgotPassword = (payload: IForgotPassword) => {
  return post(`${API_PREFIX}/forgetPassword`, payload);
};

const resetPassword = (payload: IResetPassword) => {
  return post(`${API_PREFIX}/resetPassword`, payload);
};

const saveCandidateLastActivity = (payload: ISaveCandidateLastActivity) => {
  return post(`${API_PREFIX}/saveCandidateLastActivity`, payload);
};

const logClientErrors = (payload: ILogClientErrors) => {
  return post(`${API_PREFIX}Utilities/logClientErrors`, payload);
};

const getCandidateInfo = (payload: IGetCandidateInfoById) => {
  return post(`${API_PREFIX}/getCandidateInfo`, payload);
}

const getDashBoardInfo = (payload: IGetDashboardRequest) => {
  return post(`${API_PREFIX}/getDashboardInfo`, payload);
};

const getStatusScore = (payload: IGetStatusScoreRequest) => {
  return post(`${API_PREFIX}/getDashboardScore`, payload);
};

export const evaluationPlatformService = {
  getMarketInfo,
  getEnums,
  createNewCandidateForMarket,
  createCandidateTrackForCandidate,
  getDetailsForCandidatebyCandidateTrackId,
  getTracksForCandidate,
  login,
  saveResponseForQuestionOfCandidateTrack,
  submitResponseToExpert,
  candidateViewedExpertFeedback,
  getAnswerStatus,
  forgotPassword,
  resetPassword,
  saveCandidateLastActivity,
  logClientErrors,
  getCandidateInfo,
  getDashBoardInfo,
  getStatusScore
};
