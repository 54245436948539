import React, { useState, useEffect } from 'react';

import {
    StyledPrevIcon,
    StyledNextIcon,
    PreviousIcon,
    NextIcon,
    StyledHintHeader,
    StyledHintPagination,
    StyledHintBody
} from './Hints.styles';
import { notEmpty } from 'utilities';
// import RichTextEditor from 'components/Common/Editors/RichTextEditor';
// import { handleMarkedText } from 'utilities/helperFunctions';
import ReactMarkdown from 'react-markdown';

interface IProps {
    hints: any,
}
const Hints = ({ hints }: IProps) => {
    const [currentHintIndex, setCurrentHintIndex] = useState(0);
    const [hidePrevIcon, setHidePrevIcon] = useState(false);
    const [hideNextIcon, setHideNextIcon] = useState(false);

    useEffect(() => {
        if (currentHintIndex === 0) setHidePrevIcon(true)
        else setHidePrevIcon(false);
        if (currentHintIndex === hints.length - 1) setHideNextIcon(true)
        else setHideNextIcon(false);
    }, [currentHintIndex])

    const handlePreviousHint = () => {
        setCurrentHintIndex(currentHintIndex - 1);
    };
    const handleNextHint = () => {
        setCurrentHintIndex(currentHintIndex + 1);
    };
    return (
        <div>
            { notEmpty(hints) && (<>
                <StyledHintPagination >
                    <StyledPrevIcon title="Previous Hint" onClick={handlePreviousHint} theme={{ hidePrevIcon }}>
                        <PreviousIcon />
                    </StyledPrevIcon>
                    <StyledNextIcon title="Next Hint" onClick={handleNextHint} theme={{ hideNextIcon }}>
                        <NextIcon />
                    </StyledNextIcon>
                </StyledHintPagination>
                <StyledHintHeader>Hint #{currentHintIndex + 1}</StyledHintHeader>
                <StyledHintBody>
                    <ReactMarkdown linkTarget="_blank" source={hints[currentHintIndex].title} />
                    {/* <RichTextEditor
                    doNotAllowCopy={true}
                    disabled={true}
                    id={`hintsRTE-${currentHintIndex}`}
                    value={handleMarkedText(hints[currentHintIndex])}
                    customStyles={{ height: 'auto', resize: 'none', boxShadow: 'none' }}
                /> */}
                </StyledHintBody>
            </>)}
            { !notEmpty(hints) && <h4>No hints available</h4>}
        </div>
    )
};

export default Hints;
