import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { BlueInterviewHelpLogoIcon, GitHubIcon, GoogleIcon, LinkedInIcon } from '../../assets';
import {
  Button,
  TextField,
  PasswordField,
  StyledLinkText,
  Logo,
  MarketSelectDropdown,
} from '../../components';
import { getValidMarket } from '../../utilities';
import { Formik, Field, Form } from 'formik';
import { login, getDetailsForCandidatebyCandidateTrackId } from 'store/evaluationPlatform';
import { DEFAULT_TOKEN, isProd } from 'utilities/constants';
import { useSnackbar } from 'notistack';

import * as Yup from 'yup';
import styled from 'styled-components';
import { setTrackInfoForPayment } from 'store/payment';

const LoginContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: 538px;
  margin: auto;
  .form {
    width: 100%;
  }
  .form > div,
  .form > button {
    margin-bottom: 10px;
  }

  #login-divider {
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .divider .divider__line {
    border-bottom: 1px solid #b0b0b0;
    width: 45%;
  }

  .divider .divider__text {
    margin-left: 10px;
    margin-right: 10px;
  }

  .linkedin .icon {
    background: #0274b3;
  }

  .login__footer span {
    color: #0f0f0f;
    font-size: 18px;
  }
`;

const WelcomeSection = () => (
  <div className="mt--30 mb--30">
    <div className="text--bold text--30 text--center text--black">Grow into top tier organizations.</div>
  </div>
);

const Divider = () => (
  <div id="login-divider" className="flexrow text--grey divider">
    <div className="divider__line" />
    <div className="divider__text">or</div>
    <div className="divider__line" />
  </div>
);

const LoginForm = ({
  marketList,
  handleChange,
  market,
}: {
  marketList: Array<any>;
  handleChange: any;
  market: string;
}) => (
  <div className="col w--30 form">
    <Field name="email" type="email" placeholder="Email" component={TextField} />
    <Field name="password" placeholder="Password" component={PasswordField} />
    {!isProd() && marketList && (
      <MarketSelectDropdown marketList={marketList} handleChange={handleChange} value={market} />
    )}
    <Button type="submit" className="bg--purple text--white text--bold mt-3">
      Log in
    </Button>

    {/* <Divider />

    <SocialButton icon={LinkedInIcon} color="white" background1="rgba(2, 116, 179, 0.81)" background2="#0274B3">
      Log in with LinkedIn
    </SocialButton>
    <SocialButton icon={GitHubIcon} color="white" background1="rgba(24, 22, 22, 0.71)" background2="#181616">
      Log in with GitHub
    </SocialButton>
    <SocialButton icon={GoogleIcon} color="#B0B0B0" divide="1px solid #B0B0B0" imageSize="25px">
      Log in with Google
    </SocialButton> */}
  </div>
);

const LoginFooter = () => (
  <div>
    <div className="mt--30 mb--20">
      <StyledLinkText>
        <Link to="/forgot-password">Forgot password?</Link>
      </StyledLinkText>
    </div>
    <div className="login__footer">
      <span className="mr--10">New to InterviewHelp?</span>
      <StyledLinkText>
        <Link to="/signup">Create an account</Link>
      </StyledLinkText>
    </div>
  </div>
);

const _Login = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();

  const [marketList, setMarketList] = useState<Array<any>>([]);
  useEffect(() => {
    setMarketList(props.marketList);
  }, [props.marketList]);

  const market = getValidMarket(props.market, { name: 'InterviewHelp', logo: BlueInterviewHelpLogoIcon, theme: '' });
  return (
    <Formik
      initialValues={{ email: '', password: '', market: 'INTERVIEW_HELP' }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().min(6, 'Must be 6 characters or more').required('Required'),
        market: Yup.string().required('Required'),
      })}
      onSubmit={(values: any, { setSubmitting, setErrors, setStatus, resetForm }: any) => {
        const payload = {
          token: DEFAULT_TOKEN,
          email: values.email,
          market: values.market,
          socialMediaAuthenticated: false,
          password: values.password,
        };

        props
          .login(payload)
          .then((res: any) => {
            setStatus({ success: true });
            if (res.payload?.apiStatus === 'SUCCESS') {
              enqueueSnackbar('Login Successful!', { variant: 'success', autoHideDuration: 2500 });
              localStorage.setItem('candidateId', res.payload.output.candidateId);
              if (!!res.payload.output.lastCandidateSavedSetting.lastCandidateTrackWorkedOn) {
                localStorage.setItem(
                  'candidateTrackId',
                  res.payload.output.lastCandidateSavedSetting.lastCandidateTrackIDWorkedOn
                );

                // update payment store info for track and plan
                props.setTrackInfoForPayment({
                  trackId: res.payload.output.lastCandidateSavedSetting.trackId,
                  trackPlan: res.payload.output.lastCandidateSavedSetting.plan,
                  trackName: res.payload.output.lastCandidateSavedSetting.lastCandidateTrackWorkedOn
                });

                props.getDetailsForCandidatebyCandidateTrackId({
                  token: DEFAULT_TOKEN,
                  candidateTrackId: res.payload.output.lastCandidateSavedSetting.lastCandidateTrackIDWorkedOn,
                });
                props.history.push('/question');
              } else {
                props.history.push({
                  pathname: '/settings',
                  state: { disable: true },
                  search: '?tab=TrackPage'
                });
              }
            } else {
              enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
            }
          })
          .catch((err: any) => {
            setStatus({ success: false });
            setErrors(err.message);
            setSubmitting(false);
            enqueueSnackbar(err?.message, {
              variant: 'error',
              autoHideDuration: 2500,
            });
          });
      }}
    >
      {({ values, handleChange }) => (
        <LoginContainer>
          <Form>
            <Logo logoImage={market.logo} imageSize="58px" logoText={market.name} textSize="20px" color="#315cd5" />
            <WelcomeSection />
            <LoginForm marketList={marketList} handleChange={handleChange} market={values.market} />
            <LoginFooter />
          </Form>
        </LoginContainer>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => ({
  market: state.evaluationPlatform.market,
  marketList: state.evaluationPlatform.marketList,
});

const mapDispatchToProps = {
  login,
  getDetailsForCandidatebyCandidateTrackId,
  setTrackInfoForPayment
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);
